import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@praxis/component-auth';
import { getExternalToken } from '../../utils/authHelpers';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';

import {
  lookupDevicesToCalibrate,
  lookupDevicesToCalibrateExt,
} from '../../app/calibration/startCalibrationSlice';

import { LayoutTagWalk } from '../LayoutTagWalk';
import { Grid } from '@enterprise-ui/canvas-ui-react';
import EnterpriseIcon, { CheckCircleFilledIcon } from '@enterprise-ui/icons';

export const CompletedCalibrationPage = () => {
  /* Auth related constants */
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const currLocation = useSelector(
    (state) => state.calibration.activeReaderDetails.storeId,
  );

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  useEffect(
    function refetchLatestDataForLocation() {
      if (session) {
        dispatch(lookupDevicesToCalibrate(currLocation));
      } else if (user) {
        dispatch(lookupDevicesToCalibrateExt({ currLocation, token }));
      }
    },
    [currLocation, dispatch, session, user, token],
  );

  return (
    <>
      <LayoutTagWalk
        pageTitle="Reader Calibration | Complete"
        completedCalibrate={true}
        completedSetUp={true}
        completedDone={true}
        nextPage={'/'}
        nextBtnLabel={'Done'}
        prevPage={'/calibrationSetupReader'}
        prevBtnLabel={'Next Reader'}
      >
        <Grid.Item xs={12}>
          <p className="hc-ta-center hc-fs-md">
            <EnterpriseIcon icon={CheckCircleFilledIcon} size="inline" />
            Calibration complete!
          </p>
        </Grid.Item>
        <Grid.Item xs={12}>
          <p className="hc-ta-center">
            This reader has been succesfully calibrated.
          </p>
        </Grid.Item>
        <Grid.Item xs={12}>
          <p className="hc-ta-center">
            Would you like to calibrate another reader?
          </p>
        </Grid.Item>
      </LayoutTagWalk>
    </>
  );
};
