/**
 *
 * @param {Object} user Obj containing installer's location and token
 * @returns {String} Bearer token to use for HTTP requests
 */
export const getExternalToken = (user) => {
  if (user) {
    let { token } = user;
    return token;
  }
  return false;
};
