import { useEffect } from 'react';
import { differenceInHours } from 'date-fns';

import { useLogout } from '../hooks/useLogout';

const TimeOutHandler = (props) => {
  const { extLogout } = useLogout();
  let timer = undefined;
  const events = ['click', 'scroll', 'load', 'keydown'];

  const eventHandler = () => {
    let currentTime = Date.now();
    localStorage.setItem('rfidAppLastInteraction', currentTime);
    if (timer) {
      props.onActive();
      startTimer();
    }
  };

  // Adds and removes event listener on component mount/unmount
  useEffect(() => {
    listenForEvents();

    return () => {
      stopEventListener();
      clearTimeout(timer);
    };
  });

  const startTimer = () => {
    // Checks for activity every 5 min, logs out after 15 min of no activity
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      let lastInteractionTime = JSON.parse(
        localStorage.getItem('rfidAppLastInteraction'),
      );
      let timeOutInterval = 8; // hrs
      const diff = differenceInHours(Date.now(), lastInteractionTime);

      if (diff < timeOutInterval) {
        // if last interaction < 8 hrs, keep active session
        startTimer();
        props.onActive();
      } else {
        // otherwise, logout
        props.onIdle();
        handleLogout();
      }
    }, 60 * 60000); // check every hour
  };

  const listenForEvents = () => {
    events.forEach((event) => {
      window.addEventListener(event, eventHandler);
    });
    startTimer();
  };

  const stopEventListener = () => {
    events.forEach((event) => {
      window.removeEventListener(event, eventHandler);
    });
  };

  const handleLogout = () => {
    stopEventListener();
    clearTimeout(timer);
    extLogout();
  };
};

export default TimeOutHandler;
