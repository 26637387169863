import { Link as ReactLink } from 'react-router-dom';

import { Anchor, Grid } from '@enterprise-ui/canvas-ui-react';
import EnterpriseIcon, { LockLockedIcon } from '@enterprise-ui/icons';

import './styles/adminSectionStyles.scss';

export const AdminSection = () => {
  return (
    <Grid.Container className="adminContainer">
      <Grid.Item className="hc-pa-xl">
        <p className="hc-fs-md">
          <Anchor to="/admin" className="iot-link" as={ReactLink}>
            <EnterpriseIcon
              icon={LockLockedIcon}
              size="inline"
              className="inline-icon"
            />
            Create access PIN
          </Anchor>
        </p>
      </Grid.Item>
    </Grid.Container>
  );
};
