export const createHostname = (macAddress) => {
  let partialMac = macAddress.substring(6).toUpperCase();
  let model = 'FX7500';
  return new Promise((resolve, reject) => {
    let hostname = model.concat(partialMac);

    hostname.length > 6
      ? resolve(hostname)
      : reject('Error: Unable to create hostname');
  });
};

export const createFQDN = (hostname, locationId) => {
  const hqLocations = 'T9999';
  let domain = '';

  if (hostname && locationId) {
    domain =
      locationId === hqLocations ? '.hq.target.com' : '.stores.target.com';
  }
  return hostname.concat(domain);
};
