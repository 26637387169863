import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Link as ReactLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  devicesByStore,
  deviceDetails,
  devicesByStoreExt,
} from '../../app/location/deviceByStoreSlice';
import { convertObjArrayToSelectTable } from '../../utils/helpers';
import { TrackPageView } from '../../utils/analyticsTracking';

import {
  Anchor,
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  Input,
  Spinner,
} from '@enterprise-ui/canvas-ui-react';
import { DataTable } from 'ui-library';
import { Columns } from './columns';
import { useLocation } from 'react-router';
import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';

import './styles/styles.scss';

export const ResultsPage = () => {
  //passes in params from url to results message
  const [searchParams] = useSearchParams();
  const store = searchParams.get('q');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const auth = useAuth();
  const { user } = useInstallerAuthContext();
  const { session } = auth;

  const locationByID = useSelector((state) => deviceDetails(state, store));
  const { loading, error } = useSelector((state) => state.deviceByStore);
  const deviceStatus = locationByID?.map((device) => device.workflow_status);
  const macAddress = locationByID?.map((device) => device.mac_address);
  let deviceOptions = convertObjArrayToSelectTable(macAddress, deviceStatus);

  TrackPageView('/results', 'searchResultsPage');

  const handleClick = (event) => {
    navigate(`/deviceDetails/${event.data.macaddress}`, {
      state: { previousPath: pathname },
    });
  };

  useEffect(() => {
    if (store !== null) {
      if (session) {
        dispatch(devicesByStore(store));
      } else if (user) {
        let { token } = user;
        dispatch(devicesByStoreExt({ store, token }));
      }
    }
  }, [dispatch, store, session, user]);

  return (
    <>
      <Layout.Body data-testid="ResultsPage" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to="/lookup">
                Lookup
              </Breadcrumbs.Item>
              <Breadcrumbs.Item as={ReactLink} to={'/results/?q=' + store}>
                {store}
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}>Device View</Heading>
            </Grid.Item>
            {loading && <Spinner className="center" />}
            {locationByID && (
              <Grid.Item xs={12}>
                <>
                  <p
                    className="hc-fs-xs inputPosition"
                    data-testid="totalResults"
                  >
                    {deviceOptions.length} results found for store {store}{' '}
                  </p>
                  <div className="resultsTableWrapper " data-testid="click_row">
                    <DataTable
                      rows={deviceOptions}
                      cols={Columns}
                      onRowClicked={handleClick}
                      data-testid="resultsTable"
                    />
                  </div>
                </>
              </Grid.Item>
            )}
            {error && (
              <Card
                elevation={0}
                className="hc-pa-normal cardContainer"
                data-testid="loading"
              >
                <Input.Info error>
                  There are no devices registered to this location.{' '}
                  <Anchor to="/lookup" as={ReactLink} className="iot-link">
                    Please try a different store.
                  </Anchor>
                </Input.Info>
              </Card>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
