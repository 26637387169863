import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '@praxis/component-auth';
import { logEvent, LogLevel } from '@praxis/component-logging';
import { useInstallerAuthContext } from '../hooks/useInstallerAuthContext';
import { getExternalToken } from '../utils/authHelpers';
import { rfidReaderMap } from '../utils/tagCalibrationHelpers';

import {
  calibrateReader,
  calibrateReaderExt,
  calibrationByMacSelector,
  selectLastCheck,
} from '../app/calibration/calibrationSlice';

export const useCalibrationData = ({
  readerType,
  locationId,
  macAddress,
  hostname,
  direction,
  start,
  stop,
  activeState,
}) => {
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();
  const intervalRef = useRef(null);
  const sessionTimeout = useRef(null);
  // const eventTimer = useRef(null);

  /* Selectors */
  const calibrationResponse = useSelector((state) =>
    calibrationByMacSelector(state, macAddress),
  );
  const currCheckCompleted = useSelector((state) =>
    selectLastCheck(state, macAddress),
  );
  const { error, loading } = useSelector((state) => state.calibrationByDevice);

  let quadrant = rfidReaderMap.get(direction).quadrant;
  let formattedReaderType =
    readerType === 'Hanging' ? 'HANGING' : 'WALL_MOUNTED';

  let { session_id, is_calibration_complete } = calibrationResponse;

  /* STATE VARS */
  const [token, setToken] = useState('');
  const [timer, setTimer] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [checkId, setCheckId] = useState(0);
  const [newSession, setNewSession] = useState(true);
  const [newCheck, setNewCheck] = useState(false);
  const [completedCheck, setCompletedCheck] = useState(false);
  const [calibrationError, setCalibrationError] = useState('');

  let sessionData = {
    session_id: null, // updates after 1st request
    check_id: checkId, // increases with every confirmed check
    retry_count: retryCount, // increases with every retried check
    store_id: locationId, // selector
    reader_type: formattedReaderType, // selector
    expected_mac_address: macAddress, // selector
    expected_host_name: hostname, // selector
    session_start_time: start, // same as check start
    session_stop_time: null,
    check_start_time: start, // captured when used Ready is pressed
    check_stop_time: stop, // captured when Done is pressed
    epc: '31cafe000000000000000000',
    expected_quadrants: quadrant, // map val for direction
  };

  const sendCalibrationRequest = async () => {
    await checkSessionId();
    if (session) {
      dispatch(calibrateReader(sessionData)).unwrap();
    } else if (user) {
      dispatch(calibrateReaderExt({ sessionData, token })).unwrap();
    }
  };

  // Determines if there's an ongoing session. if not, reset checks
  const checkSessionId = async () => {
    return new Promise((resolve) => {
      if (session_id && sessionData.session_id === null) {
        sessionData.session_id = session_id;
        setTimer(true);
      } else if (!session_id) {
        sessionData.check_id = 0;
        setCheckId(0);
      }
      resolve();
    });
  };

  const handleCurrSession = () => {
    if (timer) {
      intervalRef.current = setInterval(() => {
        let currRetry = sessionData.retry_count;
        sessionData.check_stop_time = Date.now();
        sessionData.retry_count = currRetry + 1;
        setRetryCount((prevRetryCount) => prevRetryCount + 1);
        setNewCheck(true);
        sendCalibrationRequest();
      }, 5000);
    }
  };

  const resetValues = () => {
    setCalibrationError('');
    setNewCheck(false);
    setRetryCount(0);
    setNewSession(true);
  };

  const stopDispatchFns = useCallback(() => {
    if (timer) {
      setTimer(false);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      clearTimeout(sessionTimeout.current);
      sessionTimeout.current = null;
      resetValues();
    }
  }, [timer]);

  // Calls stopDispatchFns after 2 min
  const beginSessionTimeout = () => {
    sessionTimeout.current = setTimeout(() => {
      if (!currCheckCompleted) {
        setCalibrationError('Service appears to be unresponsive');

        logEvent(
          {
            message: `Network error: No response from API in the time allotted`,
          },
          { level: LogLevel.Error },
        );
        stopDispatchFns();
      }
    }, 2 * 60000);
  };

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  useEffect(
    // Triggered for new session, when sesssion_id is received after first request
    function startSessionCheck() {
      if (session_id && newSession && !is_calibration_complete) {
        setCheckId(0);
        setNewSession(false);
        sendCalibrationRequest();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session_id, is_calibration_complete],
  );

  useEffect(
    function dispatchOnInterval() {
      if (timer && !currCheckCompleted) {
        handleCurrSession();
        beginSessionTimeout();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timer, currCheckCompleted],
  );

  useEffect(
    function checkComplete() {
      logEvent(
        {
          message: `Assessing if check is complete: ${currCheckCompleted}. Response:${JSON.stringify(
            calibrationResponse,
          )}`,
        },
        { level: LogLevel.Info },
      );

      if (currCheckCompleted === true && newCheck) {
        setCheckId((prevCheckCount) => prevCheckCount + 1);
        setCompletedCheck(true);
        stopDispatchFns();
        if (is_calibration_complete) setNewSession(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currCheckCompleted, stopDispatchFns, newCheck, is_calibration_complete],
  );

  useEffect(() => {
    if (activeState !== 'IN PROGRESS') {
      setCompletedCheck(false);
    }
  }, [activeState]);

  useEffect(() => {
    if (error && !loading) {
      setCalibrationError(error);

      logEvent(
        { message: `Network error: ${error}` },
        { level: LogLevel.Error },
      );

      stopDispatchFns();
    }
  }, [error, loading, stopDispatchFns]);

  return {
    sendCalibrationRequest,
    stopDispatchFns,
    completedCheck,
    calibrationError,
  };
};
