import { Grid, Layout } from '@enterprise-ui/canvas-ui-react';

import { EnterpriseIcon, BrandIcon } from '@enterprise-ui/icons';

export const Footer = () => {
  return (
    <>
      <Layout.Footer data-testid="footer" className="fixed-footer">
        <Grid.Container className="footer-wrapper">
          <Grid.Item xs={12} className="hc-ta-center">
            <EnterpriseIcon icon={BrandIcon} className="target-logo" />
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  );
};
