import { useState } from 'react';

import { useAuth } from '@praxis/component-auth';
import { useLogin } from '../../hooks/useLogin';
import { useForm } from 'react-hook-form';
import { TrackPageView } from '../../utils/analyticsTracking';
import { setCursorEnd } from '../../utils/helpers';

import {
  Grid,
  Layout,
  Heading,
  Anchor,
  Form,
  Image,
} from '@enterprise-ui/canvas-ui-react';
import { Button } from 'ui-library';
import iotLogo from '../../common/graphics/iot-logo.svg?url';

import styles from './LogIn.module.scss';
import { EnterpriseIcon, BrandIcon } from '@enterprise-ui/icons';

export const LogIn = () => {
  const auth = useAuth();
  const { login } = auth;
  TrackPageView('/login', 'loginScreen');

  const [pin, setPin] = useState('');
  const [location, setLocation] = useState('');
  const { extLogin, error, isLoading } = useLogin();
  const {
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { location: 'T' },
    mode: 'onBlur',
  }); // create form handlers and errors

  const submitLogin = async (e) => {
    e.preventDefault();
    await extLogin(pin, location);
  };

  return (
    <Layout data-testid="loginLayout" fullWidth>
      <Layout.Body includeRail>
        <Grid.Container
          className={`praxcss-height100vh ${styles.customContainer}`}
          justify="center"
        >
          <Grid.Item xs={12} md={12} lg={12} className={styles.loginHeader}>
            <Grid.Container className={styles.headerWrapper}>
              <Grid.Item>
                <Heading className={`hc-ta-center ${styles.whiteText}`}>
                  IoT Platform
                </Heading>
              </Grid.Item>
              <Grid.Item>
                <p className={`hc-fs-lg hc-ta-center  ${styles.whiteText}`}>
                  RFID Installer
                </p>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item
            xs={12}
            md={12}
            className={`hc-mb-min ${styles.logoWrapper}`}
          >
            <Image
              src={iotLogo}
              alt="IoT platform logo"
              width="125px"
              height="125px"
              type="contain"
              className={`${styles.logoImg}`}
            />
          </Grid.Item>
          <Grid.Item xs={12} md={12} className={styles.formWrapper}>
            <Form
              id="login-form"
              className={styles.loginForm}
              onSubmit={submitLogin}
            >
              <Form.Field
                className={styles.loginField}
                id="pin"
                data-testid="pin"
                label="Installer PIN"
                required={true}
                type="text"
                {...register('pin', {
                  required: true,
                  onBlur: (e) => setPin(e.target.value.toUpperCase()),
                })}
              />
              <Form.Field
                className={styles.loginField}
                id="location"
                data-testid="location"
                label="Location ID"
                required={true}
                type="text"
                error={errors.location}
                errorText={errors.location && errors.location.message}
                onFocus={(e) => setCursorEnd(e.target)}
                {...register('location', {
                  required: true,
                  pattern: {
                    value: /T[0-9]{4}/,
                    message: 'Invalid store ID! Please try again',
                  },
                  maxLength: {
                    value: 5,
                    message: 'Error! Location ID needs to be 5 characters long',
                  },
                  minlength: {
                    value: 5,
                    message: 'Error! Location ID needs to be 5 characters long',
                  },

                  onBlur: (e) => setLocation(e.target.value),
                })}
              />
              <Button
                className="hc-mt-md"
                data-testid="loginBtn"
                label="Login"
                type="submit"
                id="submit"
                disabled={!isValid || isLoading}
                fullWidth
              />
            </Form>
          </Grid.Item>
          {error && <div className={styles.loginError}>{error}</div>}
          <Grid.Item xs={10} md={6} lg={4} className="hc-ta-center hc-mt-lg">
            <EnterpriseIcon icon={BrandIcon} className="target-logo" />
            <p>
              Target Team Member? <br />
              <Anchor
                data-testid="targetLogin"
                onClick={() => login({ redirect: window.location.href })}
              >
                Login here
              </Anchor>
            </p>
          </Grid.Item>
          <Grid.Item xs={12} className="hc-ta-center"></Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </Layout>
  );
};

export default LogIn;
