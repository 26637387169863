import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.lookupByLocation;
const API_KEY = apiConfig.apiKey;

export const devicesByStore = createAsyncThunk(
  'store/getByStore',
  async (store) => {
    const response = await axios.get(`${RYDER_HOST}${PATH}${store}`, {
      headers: {
        // headers
        'x-api-key': API_KEY, // 'x-api
      },
    });
    return response.data;
  },
);

export const devicesByStoreExt = createAsyncThunk(
  'store/getByStoreExt',
  async (location) => {
    const { store, token } = location;
    const response = await axios.get(`${EXTERNAL_HOST}${PATH}${store}`, {
      headers: {
        // headers
        'x-api-key': API_KEY, // 'x-api
        Authorization: token,
      },
    });
    return response.data;
  },
);

const initialState = {
  loading: false,
  storeNumber: {},
  error: null,
};

export const deviceByStoreSlice = createSlice({
  name: 'deviceByStore',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(devicesByStore.fulfilled, (state, action) => {
        let location_id = action.meta.arg;
        state.loading = false;
        state.error = null;
        state.storeNumber[location_id] = action.payload;
      })
      .addCase(devicesByStoreExt.fulfilled, (state, action) => {
        let location_id = action.meta.arg.store;
        state.loading = false;
        state.error = null;
        state.storeNumber[location_id] = action.payload;
      })
      .addMatcher(
        isAnyOf(devicesByStore.pending, devicesByStoreExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(devicesByStore.rejected, devicesByStoreExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export default deviceByStoreSlice.reducer;

export const getDeviceStore = (state) => state.deviceByStore.storeNumber;

const getLocationId = (_, locationID) => locationID;

export const deviceStoreSelector = createSelector(
  getDeviceStore,
  getLocationId,
  (deviceByStore, locationID) => {
    return deviceByStore && deviceByStore[locationID];
  },
);

export const deviceDetails = createSelector(deviceStoreSelector, (device) => {
  if (device) {
    const deviceMetadata = device.map((device) => device.metadata);
    return deviceMetadata;
  }
});
