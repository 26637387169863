import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { TrackPageView } from '../../utils/analyticsTracking';

import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import { getDevice, getDeviceExt } from '../../app/location/deviceSlice';
import {
  addNewReader,
  addNewReaderExt,
} from '../../app/onboarding/onboardingSlice';

import {
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react';
import { DeviceDetailsCard } from '../../components/DeviceDetailsCard';
import { ImageCard } from '../../components/ImageCard';
import { StatusCard } from '../../components/StatusCard';
import { ProgressCard } from '../../components/ProgressCard';
import { Button } from 'ui-library';

import './styles/styles.scss';

export const DeviceInfo = () => {
  const { device } = useParams();
  const dispatch = useDispatch();
  let location = useLocation();
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const [wasButtonClicked, setWasButtonClicked] = useState(false);

  TrackPageView('/deviceDetails/:device', 'deviceDetailsPage');

  const { loading, error } = useSelector((state) => state.deviceByID);
  const selectedReader = useSelector((state) => state.deviceByID.deviceDetails);
  const deviceDetails = Object.values(selectedReader)[0]; //deconstructing object
  let inputData = {
    logicalName: deviceDetails?.metadata.logical_name,
    locationId: deviceDetails?.metadata.location_id,
    macAddress: deviceDetails?.metadata.mac_address,
    attachmentLink: deviceDetails?.metadata.attachment_link,
    fqdn: deviceDetails?.metadata.fqdn,
  };

  let previousRoute =
    location?.state?.previousPath || deviceDetails?.metadata.location_id;

  useEffect(() => {
    if (device) {
      if (session) {
        dispatch(getDevice(device));
      } else if (user) {
        let { token } = user;
        dispatch(getDeviceExt({ device, token }));
      }
    }
  }, [dispatch, device, session, user]);

  return (
    <>
      <Layout.Body data-testid="deviceView" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              {previousRoute === '/activeItems' ? (
                <Breadcrumbs.Item as={ReactLink} to={'/activeItems'}>
                  Active Items
                </Breadcrumbs.Item>
              ) : (
                <Breadcrumbs.Item
                  as={ReactLink}
                  to={'/results/?q=' + deviceDetails?.metadata.location_id}
                >
                  {deviceDetails?.metadata.location_id}
                </Breadcrumbs.Item>
              )}
              <Breadcrumbs.Item>
                {deviceDetails?.metadata?.mac_address}
              </Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}>Device View</Heading>
            </Grid.Item>
            {loading && <Spinner className="hz-center" />}
            {error && (
              <Card elevation={0} className="hc-pa-normal cardContainer">
                <Input.Info error>
                  There was an issue loading the details for this device. Please
                  try again later.
                </Input.Info>
              </Card>
            )}
            {deviceDetails && Object.keys(deviceDetails).length > 0 && (
              <Grid.Item xs={12}>
                <Grid.Container spacing="expanded">
                  <Grid.Item xs={12} md={4}>
                    <ImageCard
                      imageLink={deviceDetails?.metadata.attachment_link}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12} md={8}>
                    <StatusCard
                      status={
                        deviceDetails?.metadata.workflow_status || 'Unknown'
                      }
                    />
                    <ProgressCard
                      workflowData={deviceDetails?.metadata}
                      status={deviceDetails?.metadata.workflow_status}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <DeviceDetailsCard
                      friendlyName={
                        deviceDetails?.metadata.logical_name +
                          `-` +
                          deviceDetails?.metadata.friendly_location || 'Unknown'
                      }
                      locationID={
                        deviceDetails?.metadata.location_id || 'Unknown'
                      }
                      macAddress={
                        deviceDetails?.metadata.mac_address || 'Unknown'
                      }
                      hostName={deviceDetails?.metadata.host_name || 'Unknown'}
                      status={
                        deviceDetails?.metadata.workflow_status || 'Unknown'
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={12} justify="center" align="center">
                    {deviceDetails?.metadata.workflow_status === 'Failed' &&
                      session && (
                        <Button
                          label="Retry Onboarding"
                          onClick={() => {
                            if (session) {
                              dispatch(addNewReader(inputData)).unwrap();
                            } else if (user) {
                              let { token } = user;
                              dispatch(
                                addNewReaderExt({ inputData, token }),
                              ).unwrap();
                            }
                            setWasButtonClicked(true);
                          }}
                        />
                      )}
                    {wasButtonClicked && (
                      <p>
                        Onboarding has begun. This process will take up to an
                        hour. Please check back later.
                      </p>
                    )}
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
