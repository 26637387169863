import { Card, Heading, Anchor } from '@enterprise-ui/canvas-ui-react';
import { EnterpriseIcon, RedoIcon } from '@enterprise-ui/icons';
import { Link as ReactLink } from 'react-router-dom';
import './styles/cardStyles.scss';

export const DeviceDetailsCard = ({
  logicalName,
  locationID,
  macAddress,
  hostName,
  status,
  friendlyName,
}) => {
  return (
    <Card
      elevation={0}
      className="customTextPosition"
      data-testid="deviceDetails"
    >
      <Heading size={5} className="customHeader">
        Device Details{' '}
      </Heading>
      <div className="detailsWrapper">
        <p className="hc-mb-min customDevice">
          <strong>Friendly Name: </strong> {friendlyName}
        </p>
        <p className="hc-mb-min customDevice">
          <strong>Location ID: </strong> {locationID}
        </p>
        <p className="hc-mb-min customDevice">
          <strong>MAC Address: </strong> {macAddress}
        </p>
        <p className="hc-mb-min customDevice">
          <strong>Hostname: </strong> {hostName}
        </p>
      </div>
      {status === 'Started' && (
        //placeholder
        <Anchor
          to="/status"
          className="iot-link"
          as={ReactLink}
          padding-left="7em"
        >
          <EnterpriseIcon icon={RedoIcon} />
          Review Device Details
        </Anchor>
      )}
    </Card>
  );
};
