import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthProvider } from '@praxis/component-auth';
import { AnalyticsProvider } from '@praxis/component-analytics';
import { InstallerAuthContextProvider } from './context/InstallerAuthContext';
// import { HistoryRouter as Router } from 'react-router';
import { history } from './app/store';
import setupStore from './app/store';
import '@enterprise-ui/canvas-ui-css';
import { HelveticaForTarget } from '@enterprise-ui/component-font';

import './globalStyles/customStyles.scss';
import './globalStyles/rootStyles.scss';
import { Main } from './views/Main';
import apiConfig from './globalConfig/apiConfig';

const eventManagerConfiguration = {
  appName: 'iotInstallerApp',
  apiKey: apiConfig.analyticsKey,
  // the prod url is used for stability and real-time reporting of log events
  url: apiConfig.analyticsUrl,
};

const store = setupStore();

const App = () => {
  return (
    <Provider store={store}>
      <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
      <Router history={history}>
        <AuthProvider {...apiConfig.auth}>
          <AnalyticsProvider
            eventManagerConfiguration={eventManagerConfiguration}
            trackLocations
          >
            <InstallerAuthContextProvider>
              <Main />
            </InstallerAuthContextProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </Router>
    </Provider>
  );
};

export default App;
