import { Link as ReactLink } from 'react-router-dom';
import { TrackPageView } from '../../utils/analyticsTracking';

import {
  Anchor,
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  List,
} from '@enterprise-ui/canvas-ui-react';
import {
  EnterpriseIcon,
  CaretRightIcon,
  ChevronRightIcon,
} from '@enterprise-ui/icons';

export const InstallationPage = () => {
  TrackPageView('/install', 'installPage');

  return (
    <>
      <Layout.Body data-testid="installationPage" includeRail>
        <Card className="hc-pa-normal cardContainer">
          <Grid.Container xs={12}>
            <Grid.Item xs={12}>
              <Breadcrumbs>
                <Breadcrumbs.Item as={ReactLink} to="/">
                  Home
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/install">
                  Installation
                </Breadcrumbs.Item>
              </Breadcrumbs>
            </Grid.Item>
            <Grid.Item>
              <Heading size={3}>Reader Installation</Heading>
            </Grid.Item>
            <Grid.Item xs={12}>
              <p>
                Download and follow the instructions to install a new reader at
                a Target store:
              </p>
              <List>
                <List.Item className="hc-ta-center">
                  <Anchor
                    href="/docs/ST5500_HangingTransitionReaderInstall.pdf"
                    target="_blank"
                    className="iot-link"
                  >
                    <EnterpriseIcon
                      icon={CaretRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    ST5500 Hanging Transition Reader Install
                  </Anchor>
                </List.Item>
                <List.Item className="hc-ta-center">
                  <Anchor
                    href="/docs/ST5501_WallMountedTransitionReaderInstall.pdf"
                    target="_blank"
                    className="iot-link"
                  >
                    <EnterpriseIcon
                      icon={CaretRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    ST5501 Wall Mounted Transition Reader Install
                  </Anchor>
                </List.Item>
              </List>
            </Grid.Item>
            <Grid.Item xs={12}>
              <p>
                To begin, you will need to capture the MAC address of the
                reader. When you're ready please continue to the next screen.
              </p>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-mt-md">
              <Anchor to="/onboarding" as={ReactLink} className="iot-link">
                <EnterpriseIcon
                  icon={ChevronRightIcon}
                  size="inline"
                  className="inline-icon"
                />
                Continue to Onboarding
              </Anchor>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
