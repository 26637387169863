import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const MAC_ADDRESS_PATH = API_ROUTES.lookupByMacAddress;
const API_KEY = apiConfig.apiKey;

export const getItems = createAsyncThunk(
  'device/getDeviceItemsInfo',
  async (device) => {
    const response = await axios.get(
      `${RYDER_HOST}${MAC_ADDRESS_PATH}${device}`,
      {
        headers: {
          // headers
          'x-api-key': API_KEY, // 'x-api
        },
      },
    );
    return response.data;
  },
);

export const getItemsExt = createAsyncThunk(
  'device/getDeviceItemsInfoExt',
  async (device) => {
    const { items, token } = device;
    const response = await axios.get(
      `${EXTERNAL_HOST}${MAC_ADDRESS_PATH}${items}`,
      {
        headers: {
          // headers
          'x-api-key': API_KEY, // 'x-api
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

const initialState = {
  loading: false,
  deviceDetails: {},
  error: null,
};

export const deviceItemSlice = createSlice({
  name: 'deviceByItem',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(getItems.fulfilled, getItemsExt.fulfilled),
        (state, action) => {
          let deviceDetails = action.payload[0];
          let { mac_address } = deviceDetails.metadata;
          state.loading = false;
          state.error = null;
          state.deviceDetails[mac_address] = action.payload[0].metadata;
        },
      )
      .addMatcher(isAnyOf(getItems.pending, getItemsExt.pending), (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(getItems.rejected, getItemsExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export default deviceItemSlice.reducer;

export const getDeviceDetails = (state) => state.deviceByItem.deviceDetails;

export const selectDeviceDetails = createSelector(
  getDeviceDetails,
  (devices) => {
    let activeDevDetails = [];
    if (Object.keys(devices).length > 0) {
      for (const key in devices) {
        let mac_address = devices[key]['mac_address'];
        let status = devices[key]['workflow_status'];
        let currDevice = { mac_address, status };
        activeDevDetails.push(currDevice);
      }

      activeDevDetails = activeDevDetails.map((device, index) => {
        return { ...device, id: index + 1 };
      });

      return activeDevDetails;
    }
  },
);
