import { Image } from '@enterprise-ui/canvas-ui-react';

import storeImg from '../../common/graphics/calibration-store.svg?url';
import outsideImg from '../../common/graphics/calibration-outside.svg?url';
import insideImg1 from '../../common/graphics/wall-A1.svg?url';
import insideImg3 from '../../common/graphics/wall-B3.svg?url';
import outsideImg2 from '../../common/graphics/wall-D2.svg?url';
import outsideImg4 from '../../common/graphics/wall-C4.svg?url';

export const RfidGraphic = ({ placement }) => {
  const imageSelector = (placement) => {
    switch (placement) {
      case 'INSIDE':
        return storeImg;
      case 'OUTSIDE':
        return outsideImg;
      case 'INSIDE_1':
        return insideImg1;
      case 'INSIDE_3':
        return insideImg3;
      case 'OUTSIDE_2':
        return outsideImg2;
      case 'OUTSIDE_4':
        return outsideImg4;

      default:
        return;
    }
  };

  return (
    <div className="rfid-wrapper">
      <Image
        className="rfid-calibration-graphic"
        src={imageSelector(placement)}
        alt="Calibration illustration"
        width="300px"
        height="300px"
        type="contain"
      />
    </div>
  );
};
