import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@praxis/component-auth';
import { logEvent, LogLevel } from '@praxis/component-logging';
import { getExternalToken } from '../../utils/authHelpers';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import { useCalibrationData } from '../../hooks/useCalibrationData';

import {
  calibrationByMacSelector,
  selectLastCheck,
} from '../../app/calibration/calibrationSlice';
import {
  selectActiveLocation,
  selectActiveMac,
  selectActiveHost,
  selectSortedReaders,
  selectActiveType,
  readerInfo,
  lookupDevicesToCalibrate,
  lookupDevicesToCalibrateExt,
} from '../../app/calibration/startCalibrationSlice';

import { LayoutTagWalk } from '../LayoutTagWalk';
import { HangingCalibrationContainer } from '../../components/containers/HangingCalibrationContainer';
import { WallCalibrationContainer } from '../../components/containers/WallCalibrationContainer';
import { FailedCalibrationPage } from './FailedCalibrationPage';
import { Grid } from '@enterprise-ui/canvas-ui-react';

export const CalibrationPage = () => {
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* SELECTORS */
  const selectActiveReader = useSelector((state) => state.neptune.activeReader);
  const activeLocation = useSelector(selectActiveLocation);
  const activeMacAddress = useSelector(selectActiveMac);
  const activeHostname = useSelector(selectActiveHost);
  const readerType = useSelector(selectActiveType);
  const calibrationResponse = useSelector((state) =>
    calibrationByMacSelector(state, activeMacAddress),
  );
  const lastCheckStatus = useSelector((state) =>
    selectLastCheck(state, activeMacAddress),
  );
  const readersAtStore = useSelector((state) =>
    selectSortedReaders(state, activeLocation),
  );

  let {
    completed_check_count,
    last_check_actual_host_name,
    last_check_actual_friendly_location,
    last_check_actual_logical_name,
    last_check_actual_quadrants,
    orientation_status,
    location_status,
    location_actions_taken,
    is_calibration_complete,
  } = calibrationResponse;

  let lastCheckFriendlyName =
    last_check_actual_logical_name +
    ' - ' +
    last_check_actual_friendly_location;

  const initialDirection = readerType === 'Hanging' ? 'INSIDE' : 'INSIDE_1';

  /* STATE VARS */
  const [token, setToken] = useState('');
  const [activeState, setActiveState] = useState(''); // started, in progress, done, backwards, unknown
  const [results, setResults] = useState('');
  const [hostname, setHostname] = useState(activeHostname);
  const [macAddress, setMacAddress] = useState(activeMacAddress);
  const [startTime, setStartTime] = useState(0);
  const [direction, setDirection] = useState(initialDirection); // [1,3] = Inside ; [2,4] = Outside

  const {
    sendCalibrationRequest,
    stopDispatchFns,
    completedCheck,
    calibrationError,
  } = useCalibrationData({
    readerType: readerType,
    locationId: activeLocation,
    macAddress: macAddress,
    hostname: hostname,
    direction: direction,
    start: startTime,
    stop: startTime,
    activeState: activeState,
  });

  /* Dispatches lookup request to get latest details */
  const lookupReaders = () => {
    if (session) {
      dispatch(lookupDevicesToCalibrate(activeLocation));
    } else if (user) {
      dispatch(lookupDevicesToCalibrateExt({ activeLocation, token }));
    }
  };

  /* Updates view based on user actions in CalibrationContainer */
  const handleViewState = useCallback(
    (newActiveValue, newDirectionValue) => {
      setDirection(newDirectionValue);
      setActiveState(newActiveValue);

      if (results !== '') {
        setResults('');
      }
    },
    [results],
  );

  /* Updates corresponding variables for calibration time window */
  const handleTimeUpdate = useCallback((context) => {
    if (context.newStart) {
      setStartTime(context.newStart);
    }
  }, []);

  const handleResultsUpdate = useCallback(() => {
    if (lastCheckStatus === true && is_calibration_complete === false) {
      // FIRST CHECK
      // LOCATION CHECKS
      if (last_check_actual_host_name !== activeHostname) {
        // Need to check host name for wall reader too
        if (last_check_actual_host_name === 'UNKNOWN') {
          setActiveState('UNKNOWN_LOCATION');
        } else {
          setActiveState('WRONG_LOCATION');
        }
      } else {
        // ANTENNA (DIRECTION) CHECKS
        if (last_check_actual_quadrants === 'UNKNOWN') {
          setActiveState('UNKNOWN_ORIENTATION');
        } else if (last_check_actual_quadrants === 'BACKWARDS') {
          setActiveState('BACKWARDS_READER'); // for hanging reader
        } else if (last_check_actual_quadrants === 'INCORRECT') {
          setActiveState('WRONG_ANTENNA'); // for wall_reader
        } else {
          // setRetryCount(0);
          setActiveState('DONE');
        }
      }
    } else if (lastCheckStatus === true && is_calibration_complete === true) {
      // LAST CHECK
      if (location_status === 'CORRECT') {
        if (orientation_status === 'CORRECT') {
          // setRetryCount(0);
          setActiveState('DONE');
        } else {
          // Backwards_fixed or unknown
          orientation_status === 'UNKNOWN'
            ? setResults('ORIENTATION_UNKNOWN')
            : setResults('BACKWARDS_READER_FIXED');
        }
      } else if (location_status === 'UNKNOWN') {
        setResults('LOCATION_UNKNOWN');
      } else {
        // Wrong_location_fixed
        if (location_actions_taken[0] === 'WRONG_LOCATION_FIXED') {
          setHostname(last_check_actual_host_name);
          let newMac;
          if (readersAtStore.has(last_check_actual_logical_name)) {
            let detectedReader = readersAtStore.get(
              last_check_actual_logical_name,
            );
            newMac = detectedReader['mac_address'];
            setMacAddress(newMac);
          }
          dispatch(
            readerInfo({
              [last_check_actual_logical_name]: {
                host_name: last_check_actual_host_name,
                mac_address: newMac,
                friendly_location: last_check_actual_friendly_location,
                mount_type: readerType,
              },
            }),
          );
          lookupReaders();
        }
        setResults('WRONG_LOCATION_FIXED');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCheckStatus]);

  useEffect(
    function startCalibration() {
      if (activeState === 'IN_PROGRESS') {
        sendCalibrationRequest();

        logEvent(
          { message: `Starting calibration for ${activeHostname}` },
          { level: LogLevel.Info },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeState],
  );

  useEffect(
    function checkComplete() {
      if (completedCheck && activeState === 'IN_PROGRESS') {
        handleResultsUpdate();

        logEvent(
          {
            message: `Check complete for ${activeHostname}: ${JSON.stringify(
              calibrationResponse,
            )}`,
          },
          { level: LogLevel.Info },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completedCheck, activeState],
  );

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  useEffect(() => {
    if (selectActiveReader === '' && !results) {
      setActiveState('NO_READER_FOUND');
      setTimeout(() => {
        if (!activeLocation) {
          navigate('/calibrationSetupLocation');
        } else {
          navigate('/calibrationSetupReader');
        }
      }, 3000);
    }
  }, [selectActiveReader, activeLocation, navigate, results]);

  useEffect(
    function httpErrorDetected() {
      if (calibrationError) {
        setResults('NETWORK_ERROR');
      }
    },
    [calibrationError],
  );

  if (
    results === 'WRONG_LOCATION_FIXED' ||
    results === 'BACKWARDS_READER_FIXED'
  ) {
    return (
      <LayoutTagWalk
        pageTitle="Reader Calibration"
        activeCalibrate={true}
        completedSetUp={true}
      >
        <FailedCalibrationPage
          error={results}
          resetSession={true}
          updateState={handleViewState}
          type={readerType}
        />
      </LayoutTagWalk>
    );
  } else if (
    results === 'LOCATION_UNKNOWN' ||
    results === 'ORIENTATION_UNKNOWN'
  ) {
    return (
      <LayoutTagWalk
        pageTitle="Reader Calibration"
        activeCalibrate={true}
        completedSetUp={true}
      >
        <FailedCalibrationPage
          error={results}
          errorMessage={calibrationError}
          nextBtnLabel="Try again"
          nextPage="/calibrationSetupReader"
          prevPage="/"
          prevBtnLabel="Go Home"
        />
      </LayoutTagWalk>
    );
  } else if (results === 'NETWORK_ERROR') {
    return (
      <LayoutTagWalk
        pageTitle="Reader Calibration"
        activeCalibrate={true}
        completedSetUp={true}
      >
        <FailedCalibrationPage
          error={results}
          errorMessage={calibrationError}
          nextBtnLabel="Select another reader"
          nextPage="/calibrationSetupReader"
          prevPage="/"
          prevBtnLabel="Go Home"
        />
      </LayoutTagWalk>
    );
  }

  return (
    <>
      <LayoutTagWalk
        pageTitle="Reader Calibration"
        activeCalibrate={true}
        completedSetUp={true}
      >
        {calibrationError && (
          <p>
            Error! <br /> {calibrationError}
          </p>
        )}
        {activeState === 'NO_READER_FOUND' ? (
          <Grid.Item xs={12}>
            <p className="hc-fs-md hc-ta-center">
              No readers in this store have been selected yet.
              <br />
              Redirecting...
            </p>
          </Grid.Item>
        ) : (
          <Grid.Item xs={12}>
            <Grid.Container xs={12} justify="center" align="center">
              <Grid.Item xs={12}>
                {readerType === 'Hanging' ? (
                  <HangingCalibrationContainer
                    activeState={activeState}
                    updateState={handleViewState}
                    lastReaderDetected={lastCheckFriendlyName}
                    directionState={direction}
                    completedChecks={completed_check_count}
                    updateResults={handleResultsUpdate}
                    updateTime={handleTimeUpdate}
                    stopTimer={stopDispatchFns}
                  />
                ) : (
                  <WallCalibrationContainer
                    activeState={activeState}
                    updateState={handleViewState}
                    lastReaderDetected={lastCheckFriendlyName}
                    directionState={direction}
                    completedChecks={completed_check_count}
                    updateResults={handleResultsUpdate}
                    updateTime={handleTimeUpdate}
                    stopTimer={stopDispatchFns}
                  />
                )}
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}
      </LayoutTagWalk>
    </>
  );
};
