import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.rfidByLocation;
const API_KEY = apiConfig.apiKey;

const initialState = {
  locationDetails: [],
  loading: false,
  error: null,
};

export const getReaderNames = createAsyncThunk(
  'onboarding/getReaderNames',
  async (location) => {
    const response = await axios.get(`${RYDER_HOST}${PATH}${location}`, {
      headers: {
        'x-api-key': API_KEY,
      },
    });
    return response.data;
  },
);

export const getReaderNamesExt = createAsyncThunk(
  'onboarding/getReaderNamesExt',
  async (arg) => {
    const { installLocation, token } = arg;
    const response = await axios.get(
      `${EXTERNAL_HOST}${PATH}${installLocation}`,
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const locationDetailsSlice = createSlice({
  name: 'readersByLocation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(getReaderNames.pending, getReaderNamesExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(getReaderNames.fulfilled, getReaderNamesExt.fulfilled),
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.locationDetails = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(getReaderNames.rejected, getReaderNamesExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export default locationDetailsSlice.reducer;

// Selector fns
export const selectDisplayNames = (state) =>
  state.readerDetailsByStore.locationDetails.display_names;

export const selectAddress = (state) =>
  state.readerDetailsByStore.locationDetails.location_address;

export const selectMacAddresses = (state) =>
  state.readerDetailsByStore.locationDetails.mac_addresses;

export const selectLocationDetailsLoading = (state) =>
  state.readerDetailsByStore.loading;
