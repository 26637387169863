import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Layout,
} from '@enterprise-ui/canvas-ui-react';
import EnterpriseIcon, { MenuIcon, ProfileIcon } from '@enterprise-ui/icons';
import { useAuth } from '@praxis/component-auth';
import { useLogout } from '../hooks/useLogout';

import { NavLink } from 'react-router-dom';

const getInitials = (userInfo) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`;

export const Header = ({ onSideNavToggle }) => {
  const auth = useAuth();
  const { logout, session } = auth;
  const { extLogout } = useLogout();

  return (
    <>
      <Layout.MenuButton>
        <Button
          className="C-MenuButton"
          onClick={onSideNavToggle}
          iconOnly
          data-testid="headerMenuButton"
          aria-label="toggle side nav menu"
        >
          <EnterpriseIcon icon={MenuIcon} />
        </Button>
      </Layout.MenuButton>
      <Layout.Header />
      <Layout.GlobalActions>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Dropdown size="dense" location="bottom-right">
              <Button
                aria-label="Click to open dropdown menu"
                iconOnly
                className="praxcss-avatarButton"
                data-testid="headerGlobalActionsButton"
              >
                {session ? (
                  <Avatar
                    aria-label={`Avatar showing the initials for ${session.userInfo.fullName}`}
                  >
                    {getInitials(session.userInfo)}
                  </Avatar> // Display something else if Installer (icon?)
                ) : (
                  <EnterpriseIcon icon={ProfileIcon} />
                )}
              </Button>
              <Dropdown.Menu>
                <Dropdown.MenuItem as={NavLink} to={'/activeItems'}>
                  Active Items
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={session ? logout : extLogout}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  );
};
