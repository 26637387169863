import { useContext } from 'react';
import { InstallerAuthContext } from '../context/InstallerAuthContext';

export const useInstallerAuthContext = () => {
  const context = useContext(InstallerAuthContext);

  if (!context) {
    throw Error(
      'useInstallerAuthContext must be used inside an InstallerAuthContext',
    );
  }

  return context;
};
