import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH_MACADDRESS = API_ROUTES.lookupByMacAddress;
const API_KEY = apiConfig.apiKey;

const initialState = {
  loading: false,
  deviceDetails: {},
  error: null,
};

export const getDevice = createAsyncThunk(
  'device/getDeviceInfo',
  async (device) => {
    const response = await axios.get(
      `${RYDER_HOST}${PATH_MACADDRESS}${device}`,
      {
        headers: {
          // headers
          'x-api-key': API_KEY, // 'x-api
        },
      },
    );
    return response.data;
  },
);

export const getDeviceExt = createAsyncThunk(
  'device/getDeviceInfoExt',
  async (arg) => {
    const { device, token } = arg;
    const response = await axios.get(
      `${EXTERNAL_HOST}${PATH_MACADDRESS}${device}`,
      {
        headers: {
          // headers
          'x-api-key': API_KEY, // 'x-api
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const deviceSlice = createSlice({
  name: 'deviceByID',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(getDevice.fulfilled, getDeviceExt.fulfilled),
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.deviceDetails = action.payload;
        },
      )
      .addMatcher(isAnyOf(getDevice.pending, getDeviceExt.pending), (state) => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(getDevice.rejected, getDeviceExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export const { deviceState } = deviceSlice.actions;

export default deviceSlice.reducer;
