import { Grid, Image } from '@enterprise-ui/canvas-ui-react';
import { LayoutTagWalk } from '../LayoutTagWalk';

import rfidTag from '../../common/graphics/red-rfid-card.svg?url';

import styles from './TagWalkPage.module.scss';

export const SetupTagPage = () => {
  return (
    <>
      <LayoutTagWalk
        pageTitle="Reader Calibration | Setup"
        activeSetup={true}
        nextPage={'/calibrationSetupReader'}
        prevPage={'/calibrationSetupLocation'}
      >
        <Grid.Item xs={12}>
          <p className="hc-ta-center hc-fs-md">
            To begin Calibration, you will need the test RFID card provided to
            you.
          </p>
        </Grid.Item>
        <Grid.Item xs={12}>
          <Image
            alt="Close-up of hand holding red RFID test card"
            src={rfidTag}
            height="14.5em"
            width="14em"
            type="contain"
            className={styles.centeredRfidTag}
          />
        </Grid.Item>
      </LayoutTagWalk>
    </>
  );
};
