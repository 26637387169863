/* eslint-disable import/no-anonymous-default-export */
import { Stepper } from '@enterprise-ui/canvas-ui-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './styles/stepperStyles.scss';
import { selectActiveLocation } from '../app/calibration/startCalibrationSlice';

export const ProgressBar = ({
  completedSetUp,
  completedCalibrate,
  completedDone,
  activeSetup,
  activeCalibrate,
}) => {
  const navigate = useNavigate();
  const [currentActiveStep, setCurrentActiveStep] = useState(''); //Keeps track of activeState
  const activeReader = useSelector((state) => state.neptune.activeReader);
  const activeLocation = useSelector(selectActiveLocation);

  let handleClick = (event, step) => {
    //Jumps to specfic pages if user clicks on progress bar
    setCurrentActiveStep(step);
    if (step === 'Setup') {
      navigate(`/calibrationSetupLocation`); //placeholder page to verify onClick works
    } else if (step === 'Calibrate') {
      navigate(`/calibration`); //placeholder page to verify onClick works
    }
  };

  return (
    <Stepper
      onStepSelect={(event, step) => handleClick(event, step.name)} //OnClick event
      activeStep={currentActiveStep}
    >
      <Stepper.Item
        name="Setup"
        completed={completedSetUp}
        active={activeSetup} //Becomes true at certain page point
        disabled={activeCalibrate ? true : false}
      >
        Setup
      </Stepper.Item>
      <Stepper.Item
        name="Calibrate"
        completed={completedCalibrate}
        active={activeCalibrate}
        disabled={activeReader && activeLocation ? false : true} //Becomes true at certain page point (After setup is complete)
      >
        Calibrate
      </Stepper.Item>
      <Stepper.Item
        name="Done"
        completed={completedDone}
        disabled //Rendered disabled so users do not click directly to end
      >
        Done
      </Stepper.Item>
    </Stepper>
  );
};
