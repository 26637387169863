import { Card } from '@enterprise-ui/canvas-ui-react';
import './styles/messageStyles.scss';

export const Message = ({ type, children, ...props }) => {
  // TODO: Complete entire component
  if (type === 'error') {
    return (
      <Card className="errorCard" {...props}>
        {children}
      </Card>
    );
  }
};
