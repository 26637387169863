import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
// const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.tokenValidation;

const initialState = {
  bearerToken: '',
  loading: false,
  error: null,
};

export const getBearerToken = createAsyncThunk(
  'auth/getBearerToken',
  async (accessData) => {
    const { pin, location } = accessData;
    const response = await axios.post(`${RYDER_HOST}${PATH}`, {
      pin: pin,
      location_id: location,
    });
    return response.data;
  },
);

export const authTokenSlice = createSlice({
  name: 'bearerToken',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBearerToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBearerToken.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.bearerToken = action.payload;
      })
      .addCase(getBearerToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authTokenSlice.reducer;

// Selector fns
export const selectBearerToken = (state) => state.bearerToken;
