import { Link as ReactLink } from 'react-router-dom';
import { TrackPageView } from '../../utils/analyticsTracking';

import {
  Anchor,
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  List,
} from '@enterprise-ui/canvas-ui-react';
import { EnterpriseIcon, ChevronRightIcon } from '@enterprise-ui/icons';

export const SuccessPage = () => {
  TrackPageView('/success', 'onboardingSuccessPage');

  return (
    <>
      <Layout.Body data-testid="onboardingPage" includeRail>
        <Card className="hc-pa-normal cardContainer">
          <Grid.Container xs={12}>
            <Grid.Item xs={12}>
              <Breadcrumbs>
                <Breadcrumbs.Item as={ReactLink} to="/">
                  Home
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/onboarding">
                  Onboarding
                </Breadcrumbs.Item>
              </Breadcrumbs>
            </Grid.Item>

            <Grid.Item xs={12}>
              <Heading size={4} className="hc-ta-center hc-mt-normal">
                Thank you for registering a new RFID reader!
              </Heading>
            </Grid.Item>
            <Grid.Item
              xs={12}
              className="hc-pa-expanded hc-ta-center center-flex-block"
            >
              <p>
                The onboarding process will take about an hour to complete.
                Please check the{' '}
                <Anchor to="/activeItems" as={ReactLink} className="iot-link">
                  Active Items page
                </Anchor>{' '}
                to see the status of your registered readers.
              </p>

              <List>
                <List.Item>
                  <Anchor to="/onboarding" as={ReactLink} className="iot-link">
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Register another device
                  </Anchor>
                </List.Item>
                <List.Item className="hc-ta-center">
                  <Anchor to="/" as={ReactLink} className="iot-link">
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Go home
                  </Anchor>
                </List.Item>
              </List>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
