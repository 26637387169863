import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.orientationCalibration;
const API_KEY = apiConfig.apiKey;

const initialState = {
  response: {},
  lastResults: '',
  loading: false,
  error: null,
};

export const calibrateOrientation = createAsyncThunk(
  'calibration/orientation',
  async (readerDetails) => {
    const response = await axios.post(
      `${RYDER_HOST}${PATH}`,
      {
        ...readerDetails,
      },
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    return response.data;
  },
);

export const calibrateOrientationExt = createAsyncThunk(
  'calibration/orientationExt',
  async (readerDetails) => {
    const { orientationDetails, token } = readerDetails;
    const response = await axios.post(
      `${EXTERNAL_HOST}${PATH}`,
      {
        ...orientationDetails,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

// let testPayload = {
//   //   /***** Test payload 1 (backwards, checks < 4) */
//   // it works?
//   // session_id: 239,
//   // last_check_actual_direction: 'INSIDE',
//   // is_last_check_complete: true,
//   // completed_check_count: 1,
//   // required_check_count: 4,
//   // is_calibration_complete: false,
//   // orientation_status: 'UNKNOWN',
//   // actions_taken: ['NONE'],
//   /***** Test payload 2 (backwards_fixed + not_fixed) */
//   session_id: 239,
//   last_check_actual_direction: 'OUTSIDE',
//   is_last_check_complete: true,
//   completed_check_count: 1,
//   required_check_count: 4,
//   is_calibration_complete: true,
//   orientation_status: 'CORRECT',
//   actions_taken: ['NONE'],
// ***** NO READS *****
//   session_id: 350,
//   last_check_actual_direction: 'UNKNOWN',
//   is_last_check_complete: false,
//   completed_check_count: 0,
//   required_check_count: 4,
//   is_calibration_complete: false,
//   orientation_status: 'UNKNOWN',
//   actions_taken: ['NONE'],
// };

export const orientationCalibrationSlice = createSlice({
  name: 'orientationCalibration',
  initialState,
  reducers: {
    clearLastOrientation: (state) => {
      state.response = {};
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(
          calibrateOrientation.fulfilled,
          calibrateOrientationExt.fulfilled,
        ),
        (state, action) => {
          state.loading = true;
          state.error = null;
          state.response = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(calibrateOrientation.pending, calibrateOrientationExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )

      .addMatcher(
        isAnyOf(
          calibrateOrientation.rejected,
          calibrateOrientationExt.rejected,
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export const { clearLastOrientation } = orientationCalibrationSlice.actions;

export default orientationCalibrationSlice.reducer;

/* Selectors */
export const selectOrientationResponse = (state) =>
  state.orientationCalibration.response;

export const selectLastCheckVal = createSelector(
  selectOrientationResponse,
  (response) => {
    return response.is_last_check_complete;
  },
);
