import { ProgressBar } from '@enterprise-ui/canvas-ui-react';
import './styles/progressCardStyles.scss';

export const ProgressCard = ({ workflowData, status }) => {
  const {
    workflow_total_steps,
    workflow_step,
    workflow_message,
    onboarded_date,
  } = workflowData;
  let percentage = (workflow_step * 100) / workflow_total_steps;
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  };
  const date = onboarded_date ? new Date(onboarded_date) : 'Unknown';
  const formattedDate = date.toLocaleString('en-US', dateOptions);

  return (
    <div className="workflowWrapper" data-testid="workflow">
      {workflow_step ? (
        <p className="hc-fs-xs statusText">
          {workflow_step} of {workflow_total_steps} done
        </p>
      ) : (
        <p className="hc-fs-xs statusText">0 of 0 steps done</p>
      )}

      <ProgressBar percentComplete={percentage} />
      {status === 'Completed' && formattedDate !== 'Unknown' ? (
        <p className="hc-fs-xs statusText">
          {workflow_message} on <br />
          {formattedDate}
        </p>
      ) : status !== 'Unknown' ? (
        <p className="hc-fs-xs statusText">
          <b>{workflow_message}</b>
        </p>
      ) : (
        <p className="hc-fs-xs statusText">
          <b></b>
        </p>
      )}
    </div>
  );
};
