import EnterpriseIcon, {
  ErrorIcon,
  CheckCircleIcon,
  ProcessingIcon,
  HelpIcon,
} from '@enterprise-ui/icons';
/**
 * Iterates through array of objects and builds value and label needed for the HTML Select options based on corresponding inputs
 * @param {Array} arr
 * @param {string} value
 * @param {string} label
 */
export const convertObjArrayToSelectOptions = (arr) => {
  const optionsArray = [];
  if (arr != null) {
    for (let i = 0; i < arr.length; i++) {
      let entry = arr[i];
      let formattedEntry = {};

      formattedEntry['value'] = `${entry}`;
      formattedEntry['label'] = `${entry}`;

      optionsArray.push(formattedEntry);
    }
  }
  return optionsArray;
};

export const convertReaderNamesToSelectOptions = (arr) => {
  const optionsArray = [];
  if (arr != null) {
    for (let i = 0; i < arr.length; i++) {
      let entry = arr[i];
      let indexForLogical = entry.indexOf('-') - 1;
      let formattedEntry = {};
      let logicalName = entry.substring(0, indexForLogical);

      formattedEntry['value'] = `${logicalName}`;
      formattedEntry['label'] = `${entry}`;

      optionsArray.push(formattedEntry);
    }
  }
  return optionsArray;
};

export const convertObjToOptions = (obj) => {
  // value will be keys from obj
  const optionsArray = [];
  if (obj && Object.entries(obj).length > 0) {
    for (const [key, value] of Object.entries(obj)) {
      let formattedEntry = {};

      formattedEntry['label'] = `${key}`;
      formattedEntry['value'] = `${value[0]}`;

      optionsArray.push(formattedEntry);
    }
  }

  return optionsArray;
};

export const convertObjArrayToSelectTable = (address, status) => {
  const deviceArray = [];
  if (address != null) {
    for (let i = 0; i < address.length; i++) {
      let entry = address[i];
      let entrytwo = status[i];
      if (entrytwo === undefined) {
        entrytwo = 'Unknown';
      }
      let formattedEntry = {};
      formattedEntry['macaddress'] = `${entry}`;
      formattedEntry['status'] = `${entrytwo}`;
      deviceArray.push(formattedEntry);
    }
  }
  return deviceArray;
};

/**
 * Converts data URI to Blob
 * @param {string} dataURI encoded string
 */
export const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

/**
 * Sets cursor position at the end of input field
 * @param {string} input value of field
 */
export const setCursorEnd = (input) => {
  if (input.setSelectionRange) {
    input.selectionStart = input.selectionEnd = 1;
    // input.selectionEnd = input.selectionStart = 0;
  }
};

/**
 * Render status icon based on given status
 */

export const renderStatusIcon = (status) => {
  let icon;
  if (status === 'Started' || status === 'In Progress') {
    icon = (
      <EnterpriseIcon
        icon={ProcessingIcon}
        size="sm"
        className="customIconProcessing"
      />
    );
  } else if (status === 'Complete' || status === 'Completed') {
    icon = (
      <EnterpriseIcon
        icon={CheckCircleIcon}
        size="sm"
        className="customIconStarted"
      />
    );
  } else if (status === 'Failed' || status === 'Incomplete') {
    icon = (
      <EnterpriseIcon icon={ErrorIcon} size="sm" className="customIconFailed" />
    );
  } else {
    icon = (
      <EnterpriseIcon icon={HelpIcon} size="sm" className="customIconUnknown" />
    );
  }
  return icon;
};

/* Select first option from radio input given */
export const selectOptionOnLoad = (inputName) => {
  if (inputName) {
    let selection = document.querySelector(`input[name='${inputName}']`); // returns first input with given name
    if (selection) {
      selection.checked = true;
      return selection.value;
    }
    return;
  }
};
