export const createRfidVector = (x, y, id) => {
  //   let viewbox =
  //     '-6.436984167226325 -6.436984167226325 141.61365167897915 103.96636253190572'; // could change to viewbox from map
  return `
    <svg id="${id}-SVG" x="${x - 5}" y="${
      y - 4
    }" width="6" height="4" overflow="hidden" transform-origin="${x} ${y}" viewBox="0 0 500 343">
<g>
   <ellipse style="fill:#C00000;" cx="247.07" cy="171.66" rx="218.06" ry="168.61"/>
   <g>
       <path style="fill:#FFFFFF;" d="M162.49,221.81c-4.3,0-7.79-3.49-7.79-7.79s3.49-7.79,7.79-7.79c16.59,0,30.08-13.5,30.08-30.08
           c0-16.59-13.5-30.08-30.08-30.08c-4.3,0-7.79-3.49-7.79-7.79s3.49-7.79,7.79-7.79c25.18,0,45.66,20.49,45.66,45.66
           S187.67,221.81,162.49,221.81z"/>
       <path style="fill:#FFFFFF;" d="M193.97,251.45c-3.35,0-6.44-2.18-7.46-5.55c-1.24-4.12,1.09-8.47,5.21-9.71
           c26.27-7.91,44.61-32.6,44.61-60.05c0-29.59-21-55.42-49.93-61.41c-4.21-0.87-6.92-4.99-6.05-9.21c0.87-4.21,5-6.92,9.21-6.05
           c36.13,7.48,62.35,39.72,62.35,76.67c0,17.16-5.45,33.46-15.77,47.12c-9.97,13.21-24.16,23.1-39.93,27.85
           C195.47,251.35,194.71,251.45,193.97,251.45z"/>
       <path style="fill:#FFFFFF;" d="M230.19,276.54c-2.88,0-5.64-1.6-7-4.35c-1.9-3.86-0.31-8.53,3.55-10.43
           c32.91-16.18,53.35-48.98,53.35-85.61c0-40.87-26-77.15-64.69-90.28c-4.07-1.38-6.26-5.81-4.87-9.88c1.38-4.07,5.8-6.26,9.88-4.87
           c45.02,15.28,75.26,57.49,75.26,105.04c0,21.13-5.97,41.68-17.25,59.42c-10.98,17.26-26.47,31.15-44.8,40.17
           C232.52,276.28,231.35,276.54,230.19,276.54z"/>
       <path style="fill:#FFFFFF;" d="M265.38,300.6c-2.65,0-5.24-1.36-6.7-3.8c-2.21-3.69-1-8.48,2.7-10.68
           c18.7-11.16,34.35-27.01,45.28-45.84c11.25-19.39,17.2-41.57,17.2-64.13c0-52.14-31.17-98.63-79.41-118.44
           c-3.98-1.63-5.88-6.19-4.25-10.17c1.63-3.98,6.19-5.88,10.17-4.25c54.11,22.22,89.07,74.36,89.07,132.85
           c0,25.3-6.68,50.18-19.31,71.95c-12.25,21.11-29.81,38.88-50.77,51.4C268.11,300.24,266.74,300.6,265.38,300.6z"/>
   </g>
</g>
</svg>
    `;
};

export const arrow = () => {
  return `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 200 100" style="enable-background:new 0 0 200 100;" xml:space="preserve">
<path style="fill:#32864E;" d="M111,6.19l-0.14,0.13c-3.72,3.65-3.77,9.62-0.12,13.34L138.1,39.6l-99.8-0.16
	c-5.21,0-9.43,4.22-9.43,9.43v0.1c0,5.26,4.26,9.52,9.52,9.52l99.94-0.06l-28.39,21.27c-3.72,3.65-3.77,9.62-0.12,13.34l0.13,0.14
	c3.65,3.72,9.62,3.77,13.34,0.12l44.89-37.47c3.34-3.28,3.38-8.64,0.11-11.97L124.33,6.31C120.68,2.6,114.71,2.54,111,6.19z"/>
</svg>
`;
};

export const getCoords = (str) => {
  let x,
    y = '';
  if (str === null || str === '') return false;
  else {
    str = str.toLowerCase();

    let left = str.indexOf('x');
    let right = str.indexOf('y');

    x = str.substring(left + 1, right); // X coords

    for (left = right + 1; left <= str.length - 1; left++) {
      // Y coords
      let currChar = str[left];
      if (!isNaN(currChar)) {
        y += currChar;
      }
    }
  }
  return { x, y };
};

export const setReaderCoordinates = (readerNames) => {
  let readerCoords = {};
  readerNames.forEach((reader) => {
    let coords = getCoords(reader);
    readerCoords[reader] = coords;
  });
  return readerCoords;
};

export const createReaderVectors = (readerNames) => {
  let readerCoords = setReaderCoordinates(readerNames);
  let readerVectors = [];
  for (const [key, value] of Object.entries(readerCoords)) {
    let { x, y } = value;
    let newVector = createRfidVector(x, y, key);
    readerVectors.push(newVector);
  }
  return readerVectors;
};

export const hideDeptNames = (elems) => {
  let depts = [
    'order pickup',
    'guest service',
    'hosiery',
    'personal care',
    'household paper',
    'sporting goods',
    'meat/seafood',
  ];

  let foundElem = elems.filter((el) => {
    return depts.includes(el.textContent);
  });

  foundElem.forEach((label) => label.classList.add('hiddenName'));
};

export const getMacAddress = (logicalName, readers) => {
  let selected = readers.get(logicalName);
  return selected['mac_address'];
};

// Map containing key values used during calibration
export const rfidReaderMap = new Map([
  [
    'INSIDE_1',
    {
      quadrant: [2],
      antennaId: [1],
      nextDirection: 'INSIDE_3',
      referencePoint: 'A',
    },
  ],
  [
    'OUTSIDE_2',
    {
      quadrant: [3],
      antennaId: [2],
      nextDirection: 'INSIDE_1',
      referencePoint: 'D',
    },
  ],
  [
    'INSIDE_3',
    {
      quadrant: [1],
      antennaId: [3],
      nextDirection: 'OUTSIDE_4',
      referencePoint: 'B',
    },
  ],
  [
    'OUTSIDE_4',
    {
      quadrant: [4],
      antennaId: [4],
      nextDirection: 'OUTSIDE_2',
      referencePoint: 'C',
    },
  ],
  [
    'INSIDE',
    {
      quadrant: [1, 2],
      antennaId: [1, 3],
      nextDirection: 'OUTSIDE',
      referencePoint: 'store exit',
    },
  ],
  [
    'OUTSIDE',
    {
      quadrant: [3, 4],
      antennaId: [2, 4],
      nextDirection: 'INSIDE',
      referencePoint: 'sales floor',
    },
  ],
]);
