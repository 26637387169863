import { Link as ReactLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { TrackPageView } from '../../utils/analyticsTracking';

import {
  getAccessPin,
  selectAccessPin,
} from '../../app/externalAuth/pinGeneratorSlice';

import {
  Card,
  Grid,
  Heading,
  Layout,
  Form,
  Spinner,
  Breadcrumbs,
  Chip,
} from '@enterprise-ui/canvas-ui-react';
import { Button } from 'ui-library';
import { Message } from '../../components/Message';

import styles from './Admin.module.scss';

export const AdminPage = () => {
  const dispatch = useDispatch();
  const accessPin = useSelector(selectAccessPin);
  const { loading, error } = useSelector((state) => state.generatedTOTP);
  TrackPageView('/admin', 'admin');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { locationId: 'T' },
    mode: 'onBlur',
  }); // create form handlers and errors

  const onSubmit = (data, e) => {
    e.target.reset();
    dispatch(getAccessPin(data.locationId));
  };

  return (
    <>
      <Layout.Body data-testid="admin" includeRail>
        <Card className={`hc-pa-normal cardContainer ${styles.flexCard}`}>
          <Grid.Container>
            <Grid.Item xs={12}>
              <Breadcrumbs>
                <Breadcrumbs.Item as={ReactLink} to="/">
                  Home
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/install">
                  Admin
                </Breadcrumbs.Item>
              </Breadcrumbs>
            </Grid.Item>
            <Grid.Item xs={12} md={12}>
              <Heading size={3} className="hc-mt-sm ">
                Access PIN Generator
              </Heading>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pl-md">
              <p className="hc-fs-sm">
                Please enter the store ID where the installer is located:
              </p>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pl-md">
              <Form id="registration_form" onSubmit={handleSubmit(onSubmit)}>
                <Grid.Container direction="row" align="center">
                  <Grid.Item xs={8} md={4}>
                    <Form.Field
                      id="locationId"
                      data-testid="location_id"
                      label="Location ID"
                      placeholder="Example: T9999"
                      type="text"
                      required={true}
                      error={errors.locationId}
                      errorText={errors.locationId && errors.locationId.message}
                      {...register('locationId', {
                        required: true,
                        pattern: {
                          value: /T[0-9]{4}/,
                          message:
                            'Invalid location ID! Please follow this format: T1234',
                        },
                        maxLength: {
                          value: 5,
                          message:
                            'Error! Location ID needs to be 5 characters long',
                        },
                        minlength: {
                          value: 5,
                          message:
                            'Error! Location ID needs to be 5 characters long',
                        },
                      })}
                    />
                  </Grid.Item>
                  <Grid.Item xs={4}>
                    <Button label="Submit" type="submit" id="submit" />
                  </Grid.Item>
                </Grid.Container>
              </Form>
            </Grid.Item>

            <Grid.Item xs={12} className="hc-ta-center">
              {loading && <Spinner className="center" />}
              {error && (
                <Message type="error">
                  <p>There was a problem generating a PIN. Please try again.</p>
                </Message>
              )}
              {accessPin && (
                <div className="hc-mt-xl">
                  <p>Temporary access PIN</p>
                  <Chip
                    data-testid="access-pin"
                    className={styles.customChip}
                    clickable={true}
                  >
                    {accessPin}
                  </Chip>
                </div>
              )}
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
