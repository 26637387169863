import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import { codeCaptured } from '../../../app/scanner/scannerSlice';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { TrackPageView } from '../../../utils/analyticsTracking';

import { Message } from '../../../components/Message';
import {
  Anchor,
  Card,
  Grid,
  Layout,
  Breadcrumbs,
} from '@enterprise-ui/canvas-ui-react';
import { EnterpriseIcon, ChevronRightIcon } from '@enterprise-ui/icons';

import styles from './ScannerPage.module.scss';

export const ScannerPage = () => {
  TrackPageView('/scan', 'scannerPage');

  const [scannedCode, setScannedCode] = useState('');
  const [scanError, setScanError] = useState('');
  const dispatch = useDispatch();
  const qrCodeScanner = useRef(null);
  let config = {
    fps: 10,
    qrbox: { width: 250, height: 225 },
    aspectRatio: 1.777778,
    rememberLastUsedCamera: true,
    supportedScanTypes: [0],
  };

  const validateCode = (code) => {
    if (code.length !== 12) {
      setScanError(
        'Please make sure to scan the MAC/IEEE barcode on the device.',
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    const onScanSuccess = (decodedText) => {
      qrCodeScanner.current?.pause();

      if (validateCode(decodedText)) {
        setScanError('');
        setScannedCode(decodedText);
        dispatch(codeCaptured(decodedText));
      }
    };

    const onScanFailure = (error) => {
      console.warn(`Code scan error= ${error}`);
    };

    setTimeout(() => {
      let scanner = new Html5QrcodeScanner('reader', config, true);
      setScannedCode('');
      setScanError('');
      qrCodeScanner.current = scanner;
      scanner.render(onScanSuccess, onScanFailure);
    }, 1000);

    return () => {
      qrCodeScanner.current?.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout.Body data-testid="scannerPage" includeRail>
        <Card className={`hc-pa-normal ${styles.cardContainer} hc-pb-expanded`}>
          <Grid.Container xs={12}>
            <Grid.Item xs={12}>
              <Breadcrumbs>
                <Breadcrumbs.Item as={ReactLink} to="/">
                  Home
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/onboarding">
                  Onboarding
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/scan">
                  Scanner
                </Breadcrumbs.Item>
              </Breadcrumbs>
            </Grid.Item>
            <Grid.Item xs={12}>
              <div id="reader" className="reader-area"></div>
            </Grid.Item>

            <Grid.Item xs={12}>
              {scanError && (
                <Grid.Container xs={12}>
                  <Grid.Item xs={12}>
                    <Message type="error">
                      <p>
                        <strong>Invalid barcode!</strong> {scanError}
                      </p>
                    </Message>
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Anchor
                      className="iot-link"
                      onClick={() => qrCodeScanner.current?.resume()}
                    >
                      <EnterpriseIcon
                        icon={ChevronRightIcon}
                        size="inline"
                        className="inline-icon"
                      />
                      Try again
                    </Anchor>
                  </Grid.Item>
                </Grid.Container>
              )}
              {scannedCode.length > 0 && scanError === '' && (
                <>
                  <Card className="message hc-pa-normal hc-mb-xl">
                    <Grid.Container xs={12} className="center-flex-block">
                      <Grid.Item xs={12}>
                        <p className="hc-fs-md hc-ta-center">
                          Scanned barcode:
                        </p>
                      </Grid.Item>
                      <Grid.Item xs={12} style={{ display: 'flex' }}>
                        <p className={`${styles.barcodeText}`}>{scannedCode}</p>
                      </Grid.Item>
                    </Grid.Container>
                  </Card>

                  <Anchor
                    as={ReactLink}
                    className="iot-link hc-mb-xl"
                    to="/registration"
                  >
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Continue to next page
                  </Anchor>
                </>
              )}
            </Grid.Item>
            {!scannedCode && (
              <Grid.Item xs={12}>
                <Anchor as={ReactLink} className="iot-link " to="/registration">
                  <EnterpriseIcon
                    icon={ChevronRightIcon}
                    size="inline"
                    className="inline-icon"
                  />
                  Continue without scanning
                </Anchor>
              </Grid.Item>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
