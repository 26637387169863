import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  clearAllCalibrationData,
  clearErrors,
} from '../../app/calibration/calibrationSlice';
// import { selectActiveMac } from '../../app/calibration/startCalibrationSlice';
import { Grid } from '@enterprise-ui/canvas-ui-react';
import EnterpriseIcon, { ErrorFilledIcon } from '@enterprise-ui/icons';

import { Button } from 'ui-library';

export const FailedCalibrationPage = ({
  error,
  errorMessage,
  nextPage,
  nextBtnLabel,
  prevPage,
  prevBtnLabel,
  updateState,
  resetSession,
  type,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ErrorMessage = () => {
    switch (error) {
      case 'WRONG_LOCATION_FIXED':
      case 'BACKWARDS_READER_FIXED':
        return (
          <>
            <p className="hc-ta-center hc-fs-md">
              Calibration is complete, but we need a couple more checks to be
              sure.
            </p>
            <p className="hc-ta-center hc-fs-md">Please calibrate again.</p>
          </>
        );
      case 'LOCATION_UNKNOWN':
      case 'ORIENTATION_UNKNOWN':
        return (
          <p className="hc-ta-center hc-fs-md">
            Oh no, the calibration didn't quite work. Please try again - make
            sure you have the RFID test card and follow the directions.
          </p>
        );
      case 'NETWORK_ERROR':
        return (
          <>
            <p className="hc-ta-center hc-fs-md">
              The calibration service is temporarily unavailable. Please try
              again later or select a different reader.
            </p>
            <p className="hc-ta-center">
              <em>{errorMessage}</em>
            </p>
          </>
        );

      default:
        return (
          <div className="hc-ta-center center">
            <EnterpriseIcon icon={ErrorFilledIcon} size="xl" />
            <p className="hc-ta-center hc-fs-md">
              Uh oh, something went wrong.
            </p>
            <Button label="Go Home" onClick={() => navigate('/')} />
          </div>
        );
    }
  };

  return (
    <>
      <Grid.Item xs={12}>
        <Grid.Container xs={12} justify="center">
          <Grid.Item xs={12}>
            <ErrorMessage />
          </Grid.Item>
          <Grid.Container
            xs={12}
            justify="center"
            align="center"
            className="hc-mt-lg"
          >
            {prevPage && (
              <Grid.Item justify="center" align="center">
                <Button
                  label={prevBtnLabel || 'Back'}
                  onClick={() => {
                    navigate(prevPage);
                    if (errorMessage) {
                      dispatch(clearErrors());
                    }
                  }}
                  type="secondary"
                />
              </Grid.Item>
            )}
            {nextPage && (
              <Grid.Item justify="center" align="center">
                <Button
                  label={nextBtnLabel || 'Next'}
                  onClick={() => {
                    if (errorMessage) {
                      dispatch(clearErrors());
                    }
                    navigate(nextPage);
                  }}
                />
              </Grid.Item>
            )}
            {resetSession && (
              <Grid.Item justify="center" align="center">
                <Button
                  label="Continue"
                  onClick={() => {
                    let direction = type === 'Hanging' ? 'INSIDE' : 'INSIDE_1';
                    updateState('', direction);
                    dispatch(clearAllCalibrationData()); // wipe entire slice instead
                  }}
                />
              </Grid.Item>
            )}
          </Grid.Container>
        </Grid.Container>
      </Grid.Item>
    </>
  );
};
