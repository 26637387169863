import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearReaderCalibration } from '../../app/calibration/calibrationSlice';
import {
  selectActiveFriendlyName,
  selectActiveMac,
} from '../../app/calibration/startCalibrationSlice';
import { rfidReaderMap } from '../../utils/tagCalibrationHelpers';

import { Grid, Dialog, Spinner } from '@enterprise-ui/canvas-ui-react';
import { Button } from 'ui-library';
import { RfidGraphic } from '../presentation/RfidGraphic';

import styles from '../../views/Calibration/TagWalkPage.module.scss';
import '../styles/spinnerStyles.scss';

export const WallCalibrationContainer = ({
  activeState,
  directionState,
  completedChecks,
  lastReaderDetected,
  updateState,
  updateTime,
  stopTimer,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeFriendlyName = useSelector(selectActiveFriendlyName);
  const activeMac = useSelector(selectActiveMac);
  const friendlyLocation = activeFriendlyName?.split('-')[1];

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  let referencePoint = rfidReaderMap.get(directionState).referencePoint;
  let nextDirection = rfidReaderMap.get(directionState).nextDirection;

  const commonButtons = (
    <>
      <Grid.Item justify="center" align="center">
        <Button
          label="Yes, I'm sure"
          onClick={() => {
            updateState('DONE', directionState);
          }}
        />
      </Grid.Item>
      <Grid.Item justify="center" align="center">
        <Button
          label="Oops, let me try again"
          onClick={() => {
            updateState('', 'INSIDE_1');
            dispatch(clearReaderCalibration(activeMac));
          }}
        />
      </Grid.Item>
    </>
  );

  switch (activeState) {
    case 'STARTED':
      return (
        <>
          <p className="hc-ta-center hc-fs-md">
            Press <strong>Ready</strong> when you're in position
          </p>
          <Grid.Item xs={12}>
            <RfidGraphic placement={directionState} />
          </Grid.Item>
          <Grid.Container
            xs={12}
            justify="center"
            align="center"
            className="hc-mt-lg"
          >
            <Grid.Item justify="center" align="center">
              <Button
                label="Back"
                onClick={() => updateState('', directionState)}
                type="secondary"
              />
            </Grid.Item>
            <Grid.Item justify="center" align="center">
              <Button
                label="Ready"
                onClick={() => {
                  updateTime({ newStart: Date.now() });
                  updateState('IN_PROGRESS', directionState); // event handler should be dispatched based on this
                }}
              />
            </Grid.Item>
          </Grid.Container>
        </>
      );

    case 'IN_PROGRESS':
      return (
        <Grid.Container xs={12} justify="center" align="center">
          <Grid.Item xs={12}>
            <p className="hc-ta-center hc-fs-md">Please stand still.</p>
          </Grid.Item>
          <Grid.Item xs={12}>
            <p className="hc-ta-center hc-fs-sm spinnerWrapper">
              <span className="calibrationPosition">
                Calibrating <br />
                {friendlyLocation}
              </span>
              <Spinner className="spinnerSize" />
            </p>
          </Grid.Item>
          <Grid.Item justify="center" align="center">
            <Button
              label="Back"
              onClick={() => {
                updateState('STARTED', directionState);
                stopTimer();
              }}
              type="secondary"
            />
          </Grid.Item>
        </Grid.Container>
      );

    case 'OUTSIDE_CHECKS':
      return (
        <Grid.Container xs={12} justify="center" align="center">
          <Grid.Item xs={12}>
            <p className="hc-ta-center hc-fs-md">
              Now we need to check the reader's external antennas.
            </p>
          </Grid.Item>
          <Grid.Item xs={12}>
            <p className="hc-ta-center hc-fs-md">
              Please <strong>walk through the door</strong> before starting the
              next check.
            </p>
          </Grid.Item>
          <Grid.Item justify="center" align="center">
            <Button
              label="Got it!"
              onClick={() => {
                updateState('', nextDirection);
              }}
            />
          </Grid.Item>
        </Grid.Container>
      );

    case 'UNKNOWN_LOCATION':
      return (
        <>
          <p className="hc-ta-center hc-fs-md">
            Oh no, no readers in the store were able to see the test RFID card.
          </p>
          <p className="hc-ta-center hc-fs-md">
            Are you sure you're standing near reader{' '}
            <strong>{activeFriendlyName}</strong>?
          </p>
          <Grid.Container
            xs={12}
            justify="center"
            align="center"
            className="hc-mt-lg"
          >
            {commonButtons}
          </Grid.Container>
        </>
      );

    case 'WRONG_LOCATION':
      return (
        <>
          <p className="hc-ta-center hc-fs-md">
            Oh no! Reader <em>{lastReaderDetected}</em> detected you instead of{' '}
            <em>{activeFriendlyName}</em>.
          </p>
          <p className="hc-ta-center hc-fs-md">
            Are you sure you're standing near reader{' '}
            <strong>{activeFriendlyName}</strong>?
          </p>
          <Grid.Container
            xs={12}
            justify="center"
            align="center"
            className="hc-mt-lg"
          >
            {commonButtons}
          </Grid.Container>
        </>
      );

    case 'UNKNOWN_ORIENTATION':
      return (
        <>
          <p className="hc-ta-center hc-fs-md">
            Oh no, the RFID reader couldn't determine which antenna saw the tag.
          </p>
          <p className="hc-ta-center hc-fs-md">
            Are you sure you're standing as pictured?
          </p>
          <Grid.Item xs={12}>
            <RfidGraphic placement={directionState} />
          </Grid.Item>
          <Grid.Container
            xs={12}
            justify="center"
            align="center"
            className="hc-mt-lg"
          >
            {commonButtons}
          </Grid.Container>
        </>
      );
    case 'WRONG_ANTENNA':
      return (
        <>
          <p className="hc-ta-center hc-fs-md">
            Oh no, the RFID reader saw you on a different antenna than expected.
          </p>
          <p className="hc-ta-center hc-fs-md">
            Are you sure you're standing as pictured?
          </p>
          <Grid.Item xs={12}>
            <RfidGraphic placement={directionState} />
          </Grid.Item>
          <Grid.Container
            xs={12}
            justify="center"
            align="center"
            className="hc-mt-lg"
          >
            {commonButtons}
          </Grid.Container>
        </>
      );

    case 'DONE':
      return (
        <Grid.Container
          xs={12}
          justify="center"
          align="center"
          className="hc-mt-lg"
        >
          <Grid.Item xs={12} className={styles.checksWrapper}>
            <p className="hc-ta-center hc-fs-md">
              {completedChecks >= 4
                ? 'We received the calibration data for the reader!'
                : "Okay, let's do the next check!"}
            </p>
            <p className={styles.checksProgress}>
              &#x2705;{' '}
              <span>
                <strong>{completedChecks} out of 4</strong>
              </span>{' '}
              checks completed
            </p>
          </Grid.Item>

          <Grid.Item xs={12} justify="center" align="center">
            <Button
              label="Next"
              onClick={() => {
                if (completedChecks < 4) {
                  if (completedChecks === 2) {
                    updateState('OUTSIDE_CHECKS', directionState);
                  } else {
                    updateState('', nextDirection);
                  }
                } else if (completedChecks === 4) {
                  dispatch(clearReaderCalibration(activeMac));
                  navigate('/completedCalibration');
                }
              }}
            />
          </Grid.Item>
        </Grid.Container>
      );

    default:
      return (
        <>
          <Grid.Item
            data-testid="mainContent"
            xs={12}
            className="center-flex-block"
          >
            <p className="hc-ta-center hc-fs-md">To begin calibrating:</p>
            <ol>
              <li>
                Walk towards <strong>antenna {referencePoint}</strong> (see
                diagram)
              </li>
              <li>
                Hold the <strong>test card</strong> above your head and close to
                the antenna
              </li>
              <li>
                Click <strong>Next</strong>
              </li>
            </ol>
          </Grid.Item>
          <Grid.Item xs={12}>
            <RfidGraphic placement={directionState} />
          </Grid.Item>
          <Grid.Container
            xs={12}
            justify="center"
            align="center"
            className="hc-mt-lg"
          >
            <Grid.Item justify="center" align="center">
              <Button
                label="Back"
                onClick={() => {
                  if (completedChecks > 0) {
                    setIsDialogVisible(true);
                  } else {
                    navigate('/calibrationSetupReader');
                  }
                }}
                type="secondary"
              />
            </Grid.Item>
            <Grid.Item justify="center" align="center">
              <Button
                label="Next"
                onClick={() => {
                  updateState('STARTED', directionState);
                }}
              />
            </Grid.Item>
            <Dialog
              headingText="Are you sure?"
              bodyText="A calibration session is in progress. If you go back, the current session data will be lost."
              approveButtonText="Yes"
              isVisible={isDialogVisible}
              onApprove={() => {
                setIsDialogVisible(false);
                navigate('/calibrationSetupReader');
                dispatch(clearReaderCalibration(activeMac));
              }}
              onRefuse={() => {
                setIsDialogVisible(false);
              }}
              refuseButtonText="Cancel"
            />
          </Grid.Container>
        </>
      );
  }
};
