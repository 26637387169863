import { renderStatusIcon } from '../../utils/helpers';
import './styles/styles.scss';

//Renders icon status in the column cell
const statusRenderer = (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return (
    <div>
      <span> {renderStatusIcon(cellValue)}</span>

      <span>{cellValue}</span>
    </div>
  );
};
export default statusRenderer;
