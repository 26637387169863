import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const PATH = API_ROUTES.generateTotp;
const API_KEY = apiConfig.apiKey;

const initialState = {
  pin: '',
  loading: false,
  error: null,
};

export const getAccessPin = createAsyncThunk(
  'auth/getAccessPin',
  async (location) => {
    const response = await axios.get(`${RYDER_HOST}${PATH}`, {
      headers: {
        'x-api-key': API_KEY,
        location_id: location,
      },
    });
    return response.data;
  },
);

export const pinGeneratorSlice = createSlice({
  name: 'accessPin',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAccessPin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAccessPin.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.pin = action.payload;
      })
      .addCase(getAccessPin.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const selectAccessPin = (state) => state.generatedTOTP.pin;

export default pinGeneratorSlice.reducer;
