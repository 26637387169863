import { useNavigate } from 'react-router-dom';
import { Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react';
import { ProgressBar } from '../components/ProgressBar';
import { Button } from 'ui-library';
import { SectionHeader } from '../components/SectionHeader';

export const LayoutTagWalk = ({
  pageTitle,
  children,
  completedSetUp,
  completedCalibrate,
  completedDone,
  activeCalibrate,
  activeSetup,
  nextBtnLabel,
  prevBtnLabel,
  nextPage,
  prevPage,
  btnDisabled,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <SectionHeader pageHeading={pageTitle} />
      <Layout.Body data-testid={pageTitle} includeRail>
        <Card className="hc-pa-normal cardContainer">
          <Grid.Container xs={12} justify="center">
            <Grid.Item xs={12} className="hc-mb-lg hc-mt-sm">
              <ProgressBar
                completedSetUp={completedSetUp}
                completedCalibrate={completedCalibrate}
                completedDone={completedDone}
                activeCalibrate={activeCalibrate}
                activeSetup={activeSetup}
              />
            </Grid.Item>
            {children}
            <Grid.Item xs={12} md={12}>
              <Grid.Container
                justify="center"
                align="center"
                className="hc-mt-lg hc-pb-lg"
              >
                {prevPage && (
                  <Grid.Item justify="center" align="center">
                    <Button
                      label={prevBtnLabel || 'Back'}
                      onClick={() => navigate(prevPage)}
                      type="secondary"
                    />
                  </Grid.Item>
                )}
                {nextPage && nextPage.length > 0 && (
                  <Grid.Item justify="center" align="center">
                    <Button
                      label={nextBtnLabel || 'Next'}
                      onClick={() => navigate(nextPage)}
                      disabled={btnDisabled}
                    />
                  </Grid.Item>
                )}
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
