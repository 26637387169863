import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const START_PATH = API_ROUTES.startCalibration;
const LOOKUP_PATH = API_ROUTES.lookupDevicesToCalibrate;
const API_KEY = apiConfig.apiKey;

const initialState = {
  started: null,
  devicesToCalibrate: {},
  activeReaderDetails: {},
  startedLoading: false,
  devicesLoading: false,
  error: null,
};

export const startCalibration = createAsyncThunk(
  'calibration/start',
  async (payload) => {
    const response = await axios.post(
      `${RYDER_HOST}${START_PATH}`,
      {
        store_id: payload,
      },
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    return response.data;
  },
);

export const startCalibrationExt = createAsyncThunk(
  'calibration/startExt',
  async (payload) => {
    const { locationId, token } = payload;
    const response = await axios.post(
      `${RYDER_HOST}${START_PATH}`,
      {
        store_id: locationId,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const lookupDevicesToCalibrate = createAsyncThunk(
  'calibration/lookup',
  async (locationId) => {
    const response = await axios.get(
      `${RYDER_HOST}${LOOKUP_PATH}${locationId}`,
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    return response.data;
  },
);

export const lookupDevicesToCalibrateExt = createAsyncThunk(
  'calibration/lookupExt',
  async (payload) => {
    let { location, token } = payload;
    const response = await axios.get(
      `${EXTERNAL_HOST}${LOOKUP_PATH}${location}`,
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const startCalibrationSlice = createSlice({
  name: 'startCalibration',
  initialState,
  reducers: {
    storeId: (state, action) => {
      state.activeReaderDetails['storeId'] = action.payload;
    },
    readerInfo: (state, action) => {
      state.activeReaderDetails['readerInfo'] = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(lookupDevicesToCalibrate.fulfilled, (state, action) => {
        let location = action.meta.arg;
        state.devicesLoading = false;
        state.error = null;
        state.devicesToCalibrate[location] = action.payload;
      })
      .addCase(lookupDevicesToCalibrateExt.fulfilled, (state, action) => {
        let { location } = action.meta.arg;
        state.devicesLoading = false;
        state.error = null;
        state.devicesToCalibrate[location] = action.payload;
      })
      .addMatcher(
        isAnyOf(startCalibration.pending, startCalibrationExt.pending),
        (state) => {
          state.startedLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(startCalibration.fulfilled, startCalibrationExt.fulfilled),
        (state, action) => {
          state.startedLoading = false;
          state.error = null;
          state.started = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(startCalibration.rejected, startCalibrationExt.rejected),
        (state, action) => {
          state.startedLoading = false;
          state.error = action.error.message;
        },
      )
      .addMatcher(
        isAnyOf(
          lookupDevicesToCalibrate.pending,
          lookupDevicesToCalibrateExt.pending,
        ),
        (state) => {
          state.devicesLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(
          lookupDevicesToCalibrate.rejected,
          lookupDevicesToCalibrateExt.rejected,
        ),
        (state, action) => {
          state.devicesLoading = false;
          state.error = action.error.message;
        },
      );
  },
});

export const { storeId, readerInfo } = startCalibrationSlice.actions;

export default startCalibrationSlice.reducer;

/* Selectors */
const devicesToCalibrate = (state) => state.calibration.devicesToCalibrate;

const getLocation = (_, location) => location;

const getFloor = (_, location, floor) => floor;

const selectDevicesToCalibrateByStore = createSelector(
  devicesToCalibrate,
  getLocation,
  (deviceList, location) => {
    return deviceList && deviceList[location];
  },
);

const selectDevicesByFloor = createSelector(
  selectDevicesToCalibrateByStore,
  getFloor,
  (devices, floor) => {
    let devicesByFloor = devices?.filter(
      (device) => device.neptune_floor === floor,
    );
    return devicesByFloor;
  },
);
export const selectSortedReaders = createSelector(
  selectDevicesToCalibrateByStore,
  (devices) => {
    if (devices) {
      let sortedReaders = new Map();
      devices.forEach((device) => {
        let name = device.logical_name;
        sortedReaders.set(name, device);
      });
      return sortedReaders;
    }
  },
);

export const selectAddress = createSelector(
  selectDevicesToCalibrateByStore,
  (devices) => {
    if (devices) {
      return devices[0]['location_address'];
    }
  },
);

export const selectFriendlyNames = createSelector(
  selectDevicesByFloor,
  (devices) => {
    if (devices) {
      let friendlyReaderNames = [];
      devices.forEach((device) => {
        let friendlyName =
          device.logical_name + ' - ' + device.friendly_location;
        friendlyReaderNames.push(friendlyName);
      });

      return friendlyReaderNames.sort();
    }
  },
);

export const selectLogicalNames = createSelector(
  selectDevicesByFloor,
  (devices) => {
    if (devices) {
      let logicalReaderNames = [];
      devices.forEach((device) => {
        logicalReaderNames.push(device.logical_name);
      });
      return logicalReaderNames;
    }
  },
);

export const selectFloors = createSelector(
  selectDevicesToCalibrateByStore,
  (devices) => {
    if (devices) {
      let floors = {};
      devices.forEach((device) => {
        let neptune = device.neptune_floor;
        let physical = device.physical_floor;
        if (floors[physical]) {
          floors[physical].push(neptune);
        } else {
          floors[physical] = new Array(neptune);
        }
      });
      return floors;
    }
  },
);

export const activeCalibrationDetails = (state) =>
  state.calibration.activeReaderDetails;

export const selectActiveLocation = createSelector(
  activeCalibrationDetails,
  (details) => {
    return details.storeId;
  },
);

export const selectActiveMac = createSelector(
  activeCalibrationDetails,
  (details) => {
    if (details && Object.keys(details).length > 0) {
      let readerInfo = details.readerInfo;
      for (const [, value] of Object.entries(readerInfo)) {
        let mac = value['mac_address'];
        return mac;
      }
    }
  },
);

export const selectActiveHost = createSelector(
  activeCalibrationDetails,
  (details) => {
    if (details && Object.keys(details).length > 0) {
      let readerInfo = details.readerInfo;
      for (const [, value] of Object.entries(readerInfo)) {
        let host = value['host_name'];
        return host;
      }
    }
  },
);

export const selectActiveFriendlyName = createSelector(
  activeCalibrationDetails,
  (details) => {
    if (details && Object.keys(details).length > 0) {
      let readerInfo = details.readerInfo;
      for (const [key, value] of Object.entries(readerInfo)) {
        let friendlyName = `${key} - ${value['friendly_location']}`;
        return friendlyName;
      }
    }
  },
);

export const selectActiveType = createSelector(
  activeCalibrationDetails,
  (details) => {
    if (details && Object.keys(details).length > 0) {
      let readerInfo = details.readerInfo;
      for (const [, value] of Object.entries(readerInfo)) {
        let mount = value['mount_type'];
        return mount;
      }
    }
  },
);
