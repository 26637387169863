import { useState, useEffect } from 'react';

export const CalibrationStatus = ({ data }) => {
  const [locationCalibration, setLocationCalibration] = useState({});
  const [orientationCalibration, setOrientationCalibration] = useState({});

  const formatDate = function (date) {
    let index = date.indexOf('T');
    return date.substring(0, index);
  };

  useEffect(() => {
    if (data['last_location_calibration_date']) {
      let newDate = formatDate(data['last_location_calibration_date']);
      setLocationCalibration({
        date: newDate,
        status: data['last_location_calibration_status'],
      });
    } else {
      setLocationCalibration({
        status: 'Never',
      });
    }
    if (data['last_orientation_calibration_date']) {
      let newDate = formatDate(data['last_location_calibration_date']);

      setOrientationCalibration({
        date: newDate,
        status: data['last_orientation_calibration_status'],
      });
    } else {
      setOrientationCalibration({
        status: 'Never',
      });
    }
  }, [data]);

  return (
    <div>
      <p className="hc-ta-center  hc-mb-sm">
        Location last calibrated: <br />
        <strong>{locationCalibration.status}</strong>
        {locationCalibration.date && (
          <span> on {locationCalibration.date}</span>
        )}
      </p>
      <p className="hc-ta-center  hc-mb-sm">
        Orientation last calibrated: <br />
        <strong>{orientationCalibration.status}</strong>
        {orientationCalibration.date && (
          <span> on {orientationCalibration.date}</span>
        )}
      </p>
    </div>
  );
};
