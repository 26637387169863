import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.rfidOnboarding;
const API_KEY = apiConfig.apiKey;

const initialState = {
  readers: {},
  loading: false,
  error: null,
};

export const addNewReader = createAsyncThunk(
  'onboarding/addNewReader',
  async (readerInfo) => {
    const { fqdn } = readerInfo;
    const response = await axios.post(
      `${RYDER_HOST}${PATH}${fqdn}`,
      {
        ...readerInfo,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  },
);

export const addNewReaderExt = createAsyncThunk(
  'onboarding/addNewReaderExt',
  async (readerInfo) => {
    const { inputData, token } = readerInfo;
    const { fqdn } = inputData;
    const response = await axios.post(
      `${EXTERNAL_HOST}${PATH}${fqdn}`,
      {
        ...inputData,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  },
);

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addNewReader.fulfilled, (state, action) => {
        let { macAddress } = action.meta.arg;
        state.loading = false;
        state.error = null;
        state.readers[macAddress] = {
          response: action.payload,
          payload: { ...action.meta.arg, image: true },
        };
      })
      .addCase(addNewReaderExt.fulfilled, (state, action) => {
        let { macAddress } = action.meta.arg.inputData;
        state.loading = false;
        state.error = null;
        state.readers[macAddress] = {
          response: action.payload,
          payload: { ...action.meta.arg.inputData, image: true },
        };
      })
      .addMatcher(
        isAnyOf(addNewReader.pending, addNewReaderExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(addNewReader.rejected, addNewReaderExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export default onboardingSlice.reducer;

// Selector fns
export const selectRegisteredDevices = (state) => state.onboarding.readers;

export const selectDisplayNamesForDevices = createSelector(
  selectRegisteredDevices,
  (devices) => {
    let displayNames = Object.keys(devices);
    return displayNames;
  },
);

export const selectMacForDevices = createSelector(
  selectRegisteredDevices,
  (devices) => {
    let macAddresses = Object.keys(devices);
    return macAddresses;
  },
);
