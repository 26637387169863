import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.lookupByLogicalName;
const API_KEY = apiConfig.apiKey;

const initialState = {
  response: {},
  loading: false,
  error: null,
};

export const lookupDeviceByLogical = createAsyncThunk(
  'calibration/lookupDevice',
  async (reader) => {
    const response = await axios.get(`${RYDER_HOST}${PATH}${reader}`, {
      headers: {
        'x-api-key': API_KEY,
      },
    });
    return response.data;
  },
);

export const lookupDeviceByLogicalExt = createAsyncThunk(
  'calibration/lookupDeviceExt',
  async (payload) => {
    const { selectedReader, token } = payload;
    const response = await axios.get(
      `${EXTERNAL_HOST}${PATH}${selectedReader}`,
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const lookupLogicalNameSlice = createSlice({
  name: 'calibrationLookup',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(
          lookupDeviceByLogical.pending,
          lookupDeviceByLogicalExt.pending,
        ),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(
          lookupDeviceByLogical.fulfilled,
          lookupDeviceByLogicalExt.fulfilled,
        ),
        (state, action) => {
          state.loading = false;
          state.error = null;
          state.response = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(
          lookupDeviceByLogical.rejected,
          lookupDeviceByLogicalExt.rejected,
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});

export default lookupLogicalNameSlice.reducer;
