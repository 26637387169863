import { useInstallerAuthContext } from './useInstallerAuthContext';

export const useLogout = () => {
  const { dispatch } = useInstallerAuthContext();

  const extLogout = () => {
    localStorage.removeItem('installerLogin');

    // reset global state by dispatching logout action
    dispatch({ type: 'LOG_OUT' });
  };

  return { extLogout };
};
