import { useEffect } from 'react';
import { useAnalytics } from '@praxis/component-analytics';

const TrackPageView = async (path, pageName) => {
  const { trackView } = useAnalytics();
  useEffect(() => {
    trackView(`${path}`, `${pageName}`); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackView]);
};

const TrackClick = async (target, eventName) => {
  const { trackClick } = useAnalytics();
  trackClick(`${target}`, `${eventName}`);
};

export { TrackPageView, TrackClick };
