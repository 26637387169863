import { createContext, useEffect, useReducer } from 'react';

export const InstallerAuthContext = createContext();

export const installerAuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOG_IN':
      return {
        user: action.payload,
        isUserAuthenticated: true,
      };
    case 'LOG_OUT':
      return {
        user: null,
        isUserAuthenticated: false,
      };
    default:
      return state;
  }
};

export const InstallerAuthContextProvider = ({ children }) => {
  // export const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [state, dispatch] = useReducer(installerAuthReducer, {
    user: null,
    isUserAuthenticated: false,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('installerLogin'));

    if (user) {
      //TODO: Add additional check for expiration?
      dispatch({ type: 'LOG_IN', payload: user });
    }
  }, []);

  console.log('InstallerAuthContext state', state);

  return (
    <InstallerAuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </InstallerAuthContext.Provider>
  );
};
