import { useState } from 'react';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../globalConfig/apiConfig';

import { useInstallerAuthContext } from './useInstallerAuthContext';

export const useLogin = () => {
  // const RYDER_HOST = apiConfig.ryderHost;
  const EXTERNAL_HOST = apiConfig.externalHost;
  const PATH = API_ROUTES.tokenValidation;

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useInstallerAuthContext();

  const extLogin = async (pin, location) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${EXTERNAL_HOST}${PATH}`,
        {
          pin: pin,
          location_id: location,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      const token = response.data.token;

      let installerData = {
        token: token,
        installLocation: location,
      };

      localStorage.setItem('installerLogin', JSON.stringify(installerData));
      dispatch({ type: 'LOG_IN', payload: installerData });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(
        'Invalid PIN or location! Please try again or request a new PIN',
      );
    }
  };

  return { extLogin, isLoading, error };
};
