/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import merge from 'lodash/merge';

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
  },
  analytics: {
    appName: 'IoT Installer App',
  },
};

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .drone.yml
  dev: {
    apiKey: '55f755eaed8161eddf43a7a7e09b4553bd4b8e93',
    analyticsKey: '682b8244525747343fca4d1855c6f2361f45642e',
    analyticsUrl:
      'https://stgapi-internal.target.com/internal_app_analytics/v3',
    ADGroups: ['APP-OAuth2-IoT-Admin-stg', 'APP-IOT-RFID-Onboard'],
    auth: {
      // create a non-prod ID2.0 OAuth Client ID unique to your app needs at http://go/oauth
      // this clientID will not work in deployed environments
      clientId: 'polaris_rfid_ui_npe_im',
    },
    ryderHost: 'https://iotinstall.perf.target.com',
    // ryderHost: 'https://ryder.dev.target.com',
    externalHost: 'https://iotinstall.perf.target.com',
    neptuneHost: 'https://stgapi-internal.target.com/store_maps/v1',
    externalNeptuneHost: 'https://stage-api.target.com/store_maps/v1',
  },
  /*
  // additional environments can be added:
  stg: {

  },
  */
  prod: {
    apiKey: '92badc893111db7119235712c087032b4864d582',
    analyticsKey: '5ecdc454bdbf60a2ebdd01fdead9ceeb62eb6da3',
    analyticsUrl: 'https://api-internal.target.com/internal_app_analytics/v3',
    ADGroups: ['APP-OAuth2-IoT-Admin-Prod', 'APP-IOT-RFID-Onboard'],
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      // clone your non-prod ID2.0 OAuth Client ID to a production Client ID at http://go/oauth',
      // this clientID will not work in deployed environments
      clientId: 'polaris_rfid_ui_prod_im',
    },
    ryderHost: 'https://iotinstall.target.com',
    externalHost: 'https://iotinstall.target.com',
    neptuneHost: 'https://api-internal.target.com/store_maps/v1',
    externalNeptuneHost: 'https://api.target.com/store_maps/v1',
  },
};

export const API_ROUTES = {
  generateTotp: '/rfid_device_onboard/v1/generate/totp',
  tokenValidation: '/rfid_device_onboard/v1/validate/totp',
  rfidOnboarding: '/rfid_device_onboard/v1/install/',
  rfidByLocation: '/rfid_device_onboard/v1/location_details/',
  lookupByLocation: '/rfid_device_onboard/v1/lookup/location_id/',
  lookupByDisplayName: '/rfid_device_onboard/v1/lookup/display_name/',
  lookupByMacAddress: '/rfid_device_onboard/v1/lookup/mac_address/',
  lookupByLogicalName: '/rfid_device_onboard/v1/lookup/logical_name/',
  mapByLocation: '/svg_images?location_id=',
  calibration: '/rfid_device_onboard/v1/calibrate/reader',
  orientationCalibration: '/rfid_device_onboard/v1/calibrate/orientation',
  locationCalibration: '/rfid_device_onboard/v1/calibrate/location',
  startCalibration: '/rfid_device_onboard/v1/calibrate/start',
  lookupDevicesToCalibrate: '/rfid_device_onboard/v1/lookup/devices/',
};

export const DEVELOPMENT = 'dev';
export const PRODUCTION = 'prod';

export const ENV = {
  DEVELOPMENT,
  PRODUCTION,
};

let hostname = '';

if (window.location && window.location.host) {
  [hostname] = window.location.host.split(':');
}

const appEnv =
  hostname === 'iotinstall.target.com' || hostname === 'polaris.prod.target.com' // Change to make sure prod is only in "iotinstall.target.com"
    ? ENV.PRODUCTION
    : ENV.DEVELOPMENT;

const config = envConfigs[appEnv];

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config);

export default apiConfig;

// Uncomment for debugging
// if (process.env.NODE_ENV !== 'production') {
//   console.log(`${appEnv} ENV apiConfig:`, apiConfig);
// }
