import { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useInstallerAuthContext } from '../hooks/useInstallerAuthContext';
import * as d3 from 'd3';

import { selectMapByStore } from '../app/neptune/neptuneSlice';
import { selectLogicalNames } from '../app/calibration/startCalibrationSlice';
import {
  hideDeptNames,
  createReaderVectors,
  setReaderCoordinates,
} from '../utils/tagCalibrationHelpers';

import EnterpriseIcon, {
  ChevronUpDoubleIcon,
  ChevronDownDoubleIcon,
} from '@enterprise-ui/icons';
import './styles/neptuneMapStyles.scss';

export const NeptuneMap = ({ storeId, calibrationState, direction }) => {
  const { user } = useInstallerAuthContext();
  const svgWrapper = useRef(null);

  const floorNum = useSelector((state) => state.neptune.storeFloor);
  const mapSelector = useSelector((state) =>
    selectMapByStore(state, storeId, floorNum),
  );
  const selectActiveReader = useSelector((state) => state.neptune.activeReader);
  const rfidReaders = useSelector((state) =>
    selectLogicalNames(state, storeId, floorNum),
  );

  const [readerCoords, setReaderCoords] = useState({});
  const [readerVectors, setReaderVectors] = useState([]);
  const [mapReady, setMapReady] = useState(false);

  const zoom = d3
    .zoom()
    .scaleExtent([1, 3])
    .extent([
      [0, 0],
      [150, 100],
    ])
    .translateExtent([
      [0, 0],
      [150, 100],
    ]); // Set zoom scale + translate limits

  const d3Map = d3.select(svgWrapper.current); // Select main svg using D3

  const handleZoom = function (e) {
    d3Map.select('#content').attr('transform', e.transform); // Select main g DOM group to transform
  };

  const focusOnReader = useCallback(
    (reader) => {
      let { x, y } = readerCoords[reader];
      d3Map
        .transition()
        .duration(500)
        .call(
          zoom.transform,
          d3.zoomIdentity
            .translate(150, 100)
            .scale(3)
            .translate(-x + -30, -y + -10), // Takes into account neg values in viewbox from svg map
        );
    },
    [d3Map, readerCoords, zoom],
  );

  zoom.on('zoom', handleZoom); // Function to call on zoom
  d3Map.call(zoom); // Apply zoom behavior to the SVG map

  const addReaderToMap = (vector) => {
    if (svgWrapper.current !== null) {
      let svgElem = svgWrapper.current.querySelector('svg #content');
      if (!svgElem) {
        return false;
      } else {
        svgElem.innerHTML += vector;
      }
    }
  };

  useEffect(() => {
    const fixMapView = () => {
      if (user) {
        let mapWrapper = document.querySelector('div.mapWrapper');
        mapWrapper.classList.add('externalLayer');
      }
    };

    async function embedMap() {
      return new Promise((resolve) => {
        if (mapSelector && svgWrapper.current) {
          svgWrapper.current.innerHTML = mapSelector;
        }
        let deptLabels = Array.from(
          document.querySelectorAll('.adjacency-name'),
        );
        hideDeptNames(deptLabels);
        fixMapView();
        resolve();
      });
    }

    async function embedReaders() {
      return new Promise((resolve) => {
        if (readerVectors.length > 0) {
          readerVectors.forEach((vector) => addReaderToMap(vector));
          resolve();
        }
      });
    }

    async function addVectors() {
      await embedMap();
      await embedReaders();
      setMapReady(true);
    }
    addVectors();
  }, [mapSelector, readerVectors, user]);

  useEffect(
    function callVectorFns() {
      if (rfidReaders && rfidReaders.length > 0) {
        let vectors = createReaderVectors(rfidReaders);
        setReaderCoords(setReaderCoordinates(rfidReaders)); // Used in D3 transitions
        setReaderVectors(vectors);
      }
    },
    [rfidReaders],
  );

  useEffect(() => {
    const highlightSelectedReader = (reader) => {
      let unselected = rfidReaders.filter((curr) => curr !== reader);
      unselected?.forEach((vector) => {
        let readerSVG = document.querySelector(`#${vector}-SVG`);
        let ellipse = readerSVG.querySelector('ellipse');
        ellipse.style.cssText += 'fill: rgb(192,0,0);';
      });

      let selectedReader = document.querySelector(`#${reader}-SVG`);
      let ellipse = selectedReader.querySelector('ellipse');
      ellipse.style.cssText += 'fill: rgb(61, 112, 214); !important';
    };

    if (mapReady && selectActiveReader && storeId !== 'T7820') {
      highlightSelectedReader(selectActiveReader);
      focusOnReader(selectActiveReader);
    }
  }, [mapReady, selectActiveReader, storeId, rfidReaders, focusOnReader]);

  return (
    <>
      <div
        data-testid="neptuneMap"
        className="mapWrapper"
        ref={svgWrapper}
      ></div>
      {direction !== '' && calibrationState === 'DONE' && (
        <div className="directionResults">
          <p className="hc-ta-center">
            <strong>{direction}</strong> EVENT{' '}
            <span>
              {direction === 'OUTSIDE' ? (
                <EnterpriseIcon size="md" icon={ChevronDownDoubleIcon} />
              ) : (
                <EnterpriseIcon size="md" icon={ChevronUpDoubleIcon} />
              )}
            </span>
          </p>
        </div>
      )}
    </>
  );
};
