import { Link as ReactLink } from 'react-router-dom';
import { TrackPageView } from '../../utils/analyticsTracking';

import {
  Anchor,
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  List,
} from '@enterprise-ui/canvas-ui-react';
import { EnterpriseIcon, ChevronRightIcon } from '@enterprise-ui/icons';

export const OnboardingPage = () => {
  TrackPageView('/onboarding', 'onboardingPage');

  return (
    <>
      <Layout.Body data-testid="onboardingPage" includeRail>
        <Card className="hc-pa-normal cardContainer">
          <Grid.Container xs={12}>
            <Grid.Item xs={12}>
              <Breadcrumbs>
                <Breadcrumbs.Item as={ReactLink} to="/">
                  Home
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/install">
                  Installation
                </Breadcrumbs.Item>
                <Breadcrumbs.Item as={ReactLink} to="/onboarding">
                  Onboarding
                </Breadcrumbs.Item>
              </Breadcrumbs>
            </Grid.Item>
            <Grid.Item>
              <Heading size={3}>Device Registration</Heading>
            </Grid.Item>

            <Grid.Item xs={12}>
              <p>
                Scan the MAC/IEEE barcode on the back of the reader. You can
                also upload an image of the barcode or manually input the
                information.
              </p>

              <List>
                <List.Item className="hc-ta-center">
                  <Anchor to="/scan" as={ReactLink} className="iot-link">
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Scan barcode
                  </Anchor>
                </List.Item>
                <List.Item className="hc-ta-center">
                  <Anchor
                    to="/registration"
                    as={ReactLink}
                    className="iot-link"
                  >
                    <EnterpriseIcon
                      icon={ChevronRightIcon}
                      size="inline"
                      className="inline-icon"
                    />
                    Continue without scanning
                  </Anchor>
                </List.Item>
              </List>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
