import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useAuth } from '@praxis/component-auth';
import { getExternalToken } from '../../utils/authHelpers';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';
import { convertObjToOptions, selectOptionOnLoad } from '../../utils/helpers';

import {
  lookupDevicesToCalibrate,
  lookupDevicesToCalibrateExt,
  storeId,
  selectAddress,
  selectFloors,
} from '../../app/calibration/startCalibrationSlice';
import { setActiveReader, setStoreFloor } from '../../app/neptune/neptuneSlice';

import { Grid, Form, Input } from '@enterprise-ui/canvas-ui-react';
import { LayoutTagWalk } from '../LayoutTagWalk';
import { Button } from 'ui-library';
import { Message } from '../../components/Message';

import styles from './TagWalkPage.module.scss';

export const SetupLocationPage = () => {
  /* Auth related constants */
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [location, setLocation] = useState('T');
  const [activeLocation, setActiveLocation] = useState(false);

  const storeAddress = useSelector((state) => selectAddress(state, location));
  const floors = convertObjToOptions(
    useSelector((state) => selectFloors(state, location)),
  );
  const { error } = useSelector((state) => state.calibration);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { location: 'T' },
    mode: 'onBlur',
  }); // create form handlers and errors

  const handleSearch = () => {
    if (session) {
      dispatch(lookupDevicesToCalibrate(location));
    } else if (user) {
      dispatch(lookupDevicesToCalibrateExt({ location, token }));
    }
    dispatch(storeId(location));
    dispatch(setActiveReader()); // clears previously selected reader in store
    checkLocation();
  };

  const checkLocation = () => {
    if (location.length > 4) {
      setActiveLocation(true);
    }
  };

  useEffect(() => {
    setToken(getExternalToken(user));
  }, [user]);

  useEffect(() => {
    if (floors.length > 0) {
      setTimeout(() => {
        let defaultFloor = selectOptionOnLoad('floorNum');
        dispatch(setStoreFloor(defaultFloor));
      }, 500);
    }
  }, [floors, dispatch]);

  return (
    <>
      <LayoutTagWalk
        pageTitle="Reader Calibration | Setup"
        activeSetup={true}
        nextBtnLabel="Next"
        nextPage={
          activeLocation === true && floors.length > 0 && !error
            ? '/calibrationSetupTag'
            : ''
        }
        prevBtnLabel="Go Home"
        prevPage={activeLocation === true && floors.length > 0 ? '/' : ''}
      >
        <Grid.Item xs={12} md={5}>
          <p className="hc-pl-lg">Enter store or location ID:</p>
          <Form
            id="lookup_search"
            onSubmit={handleSubmit(handleSearch)}
            className="hc-pl-lg hc-pr-lg"
          >
            <div className={styles.storeIdWrapper}>
              <Form.Field
                type="text"
                label="Store ID"
                data-testid="storeId"
                id="storeID"
                className={styles.fullWidthField}
                error={errors.location}
                errorText={errors.location && errors.location.message}
                placeholder="T9999"
                maxLength="5"
                required={true}
                {...register('location', {
                  required: true,
                  pattern: {
                    value: /T[0-9]{4}/,
                    message:
                      'Invalid location ID! Please follow this format: T1234',
                  },
                  minlength: {
                    value: 5,
                    message: 'Error! Location ID needs to be 5 characters long',
                  },
                  onBlur: (e) => {
                    setLocation(e.target.value);
                  },
                  onChange: (e) => {
                    if (e.target.value.length < 5) {
                      setActiveLocation(false);
                    }
                    setLocation(e.target.value);
                  },
                })}
              />
              <Button className="hc-mt-xs" label="&#8594;" type="submit" />
            </div>
            {storeAddress && activeLocation && (
              <p className="hc-fs-xs hc-pl-xs">
                Address: <em>{storeAddress}</em>
              </p>
            )}
            {activeLocation && floors.length > 0 && (
              <>
                <p>Select floor:</p>
                <Input.Radio
                  className={styles.customRadioInput}
                  id="floorNum"
                  options={floors}
                  onUpdate={(id, value) => {
                    dispatch(setStoreFloor(value));
                  }}
                />
              </>
            )}
          </Form>
          {error && activeLocation && (
            <Message type="error">
              <p className="hc-fs-md hc-ta-center">Error!</p>
              <p className="hc-mt-xs hc-ta-center">
                {error}. Please make sure you entered the correct store.
              </p>
            </Message>
          )}
        </Grid.Item>
      </LayoutTagWalk>
    </>
  );
};
