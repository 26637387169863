// Data grid cols
// Data grid columns

import statusRenderer from './statusRenderer';

export const Columns = [
  {
    field: 'mac_address',
    filter: true,
  },
  {
    field: 'status',
    cellRenderer: statusRenderer,
    filter: true,
  },
];
