/* eslint-disable no-mixed-operators */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';

import {
  selectFriendlyNames,
  readerInfo,
  startCalibration,
  startCalibrationExt,
  selectSortedReaders,
} from '../../app/calibration/startCalibrationSlice';
import { clearReaderCalibration } from '../../app/calibration/calibrationSlice';
import {
  setActiveReader,
  getMap,
  getMapExt,
  selectMapByStore,
} from '../../app/neptune/neptuneSlice';
import { convertReaderNamesToSelectOptions } from '../../utils/helpers';
import { getMacAddress } from '../../utils/tagCalibrationHelpers';

import { Grid, Image } from '@enterprise-ui/canvas-ui-react';
import { LayoutTagWalk } from '../LayoutTagWalk';
import { NeptuneMap } from '../../components/NeptuneMap';
import { Select } from '../../components/Select';
import { Message } from '../../components/Message';
import { CalibrationStatus } from '../../components/CalibrationStatus';
import mapPlaceholder from '../../common/graphics/map-placeholder.svg?url';

import styles from './TagWalkPage.module.scss';

export const SetupReaderPage = () => {
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();
  const dispatch = useDispatch();
  let hostname = window.location.hostname;

  const locationId = useSelector(
    (state) => state.calibration.activeReaderDetails.storeId,
  );
  const floorNum = useSelector((state) => state.neptune.storeFloor);
  const rfidReaders = useSelector((state) =>
    selectFriendlyNames(state, locationId, floorNum),
  );
  const readersInStore = useSelector((state) =>
    selectSortedReaders(state, locationId),
  );
  const mapSelector = useSelector((state) =>
    selectMapByStore(state, locationId, floorNum),
  );

  const [token, setToken] = useState('');
  const [pageStatus, setPageStatus] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [selectedReader, setSelectedReader] = useState('');
  const [calibrationInfo, setCalibrationInfo] = useState('');
  const [friendlyLocation, setFriendlyLocation] = useState('Unknown');
  const [deviceHealth, setDeviceHealth] = useState('');

  let selectedReaderDetails = readersInStore?.get(selectedReader);

  const handleRequestDispatch = useCallback(() => {
    let selectedReaderMac = getMacAddress(selectedReader, readersInStore);
    if (session) {
      dispatch(startCalibration(locationId));
    } else if (user) {
      dispatch(startCalibrationExt({ locationId, token }));
    }
    dispatch(clearReaderCalibration(selectedReaderMac)); // Clears calibration data from Redux store (if any)
  }, [
    session,
    user,
    token,
    locationId,
    selectedReader,
    dispatch,
    readersInStore,
  ]);

  useEffect(
    function setExternalToken() {
      if (user) {
        let { token } = user; // External user token
        setToken(token);
      } else if (session && hostname === 'iotinstall.target.com') {
        let token = localStorage.getItem('access_token'); // TM token
        setToken(token);
      }
    },
    [user, hostname, session],
  );

  useEffect(
    function dispatchMapRequest() {
      if (locationId) {
        let storeId = locationId.substr(1);

        if (user || hostname === 'iotinstall.target.com') {
          dispatch(getMapExt({ storeId, floorNum, token }));
        } else {
          dispatch(getMap({ storeId, floorNum }));
        }
      }
    },
    [dispatch, locationId, token, user, hostname, floorNum],
  );

  useEffect(
    function setupNavReset() {
      if (pageStatus === 'active') {
        setNextPage('/calibration');
      }
    },
    [pageStatus, dispatch],
  );

  useEffect(() => {
    if (selectedReader) {
      dispatch(setActiveReader(selectedReader)); // used in neptune map map
      handleRequestDispatch();
      setTimeout(() => {
        requestAnimationFrame(() => {
          window.scrollTo({
            top: document.documentElement.scrollHeight - window.innerHeight,
            left: 0,
            behavior: 'smooth',
          });
        });
      }, 3);
    }
  }, [selectedReader, dispatch, handleRequestDispatch]);

  useEffect(
    function setActiveReaderInfo() {
      if (selectedReaderDetails) {
        let {
          calibration,
          host_name,
          mac_address,
          friendly_location,
          device_is_healthy,
          mount_type,
        } = selectedReaderDetails;

        dispatch(
          readerInfo({
            [selectedReader]: {
              host_name,
              mac_address,
              friendly_location,
              mount_type,
            },
          }),
        );

        setCalibrationInfo(calibration);
        setFriendlyLocation(friendly_location);
        setDeviceHealth(device_is_healthy);
      }
    },
    [selectedReader, dispatch, selectedReaderDetails],
  );

  return (
    <>
      <LayoutTagWalk
        pageTitle="Reader Calibration | Setup"
        nextPage={nextPage}
        prevPage={'/calibrationSetupTag'}
        nextBtnLabel="Start calibration"
        activeSetup={true}
        btnDisabled={!deviceHealth}
      >
        <Grid.Item xs={12} md={6}>
          <Select
            id="displayName"
            label="Select reader to calibrate*"
            name="displayName"
            options={convertReaderNamesToSelectOptions(rfidReaders)}
            required
            onChange={(e) => {
              setSelectedReader(e.value);
              setPageStatus('active');
            }}
            className="hc-mb-xl"
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          {typeof mapSelector === 'string' &&
          mapSelector?.includes('Unavailable') ? (
            <div className={styles.mapPlaceholderWrapper}>
              <Image
                src={mapPlaceholder}
                alt="Interactive map is unavailable"
                width="auto"
                height="265px"
                type="contain"
              />
            </div>
          ) : (
            <NeptuneMap storeId={locationId} />
          )}
        </Grid.Item>
        {selectedReader && (
          <Grid.Item xs={12} className="hc-mt-md center-flex-block">
            <p className="hc-mt-sm hc-mb-sm">
              Located in: <strong>{friendlyLocation}</strong>
            </p>
            {calibrationInfo && Object.keys(calibrationInfo).length > 0 && (
              <CalibrationStatus data={calibrationInfo} />
            )}
            <p>
              Health status:{' '}
              <strong>
                {deviceHealth !== undefined &&
                  (deviceHealth ? 'Healthy' : 'Unhealthy')}
                {deviceHealth === undefined && 'Unknown'}
              </strong>
            </p>
            {!deviceHealth && (
              <Message type="error">
                <p className="hc-mt-xs hc-ta-center">
                  The selected device is currently unavailable. Please choose
                  another device or try again later.
                </p>
              </Message>
            )}
          </Grid.Item>
        )}
      </LayoutTagWalk>
    </>
  );
};
