import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios from 'axios';
import apiConfig, { API_ROUTES } from '../../globalConfig/apiConfig';

const RYDER_HOST = apiConfig.ryderHost;
const EXTERNAL_HOST = apiConfig.externalHost;
const PATH = API_ROUTES.locationCalibration;
const API_KEY = apiConfig.apiKey;

const initialState = {
  response: {},
  loading: false,
  error: null,
};

export const calibrateLocation = createAsyncThunk(
  'calibration/location',
  async (locationDetails) => {
    const response = await axios.post(
      `${RYDER_HOST}${PATH}`,
      {
        ...locationDetails,
      },
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    return response.data;
  },
);

export const calibrateLocationExt = createAsyncThunk(
  'calibration/locationExt',
  async (payload) => {
    const { sessionData, token } = payload;
    const response = await axios.post(
      `${EXTERNAL_HOST}${PATH}`,
      {
        ...sessionData,
      },
      {
        headers: {
          'x-api-key': API_KEY,
          Authorization: token,
        },
      },
    );
    return response.data;
  },
);

export const locationCalibrationSlice = createSlice({
  name: 'locationCalibration',
  initialState,
  reducers: {
    clearLocationState: (state) => {
      state.response = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(calibrateLocationExt.fulfilled, (state, action) => {
        let session = action.meta.arg.sessionData.expected_mac_address;
        state.loading = true;
        state.error = null;
        state.response[session] = action.payload;
      })
      .addCase(calibrateLocation.fulfilled, (state, action) => {
        let session = action.meta.arg.expected_mac_address;
        state.loading = true;
        state.error = null;
        state.response[session] = action.payload;
      })
      .addMatcher(
        isAnyOf(calibrateLocation.pending, calibrateLocationExt.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(calibrateLocation.rejected, calibrateLocationExt.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        },
      );
  },
});
export const { clearLocationState } = locationCalibrationSlice.actions;

export default locationCalibrationSlice.reducer;

export const getLocationResponse = (state) =>
  state.locationCalibration.response;

const getMacAddress = (_, macAddress) => macAddress;

export const calibrationByMacSelector = createSelector(
  getLocationResponse,
  getMacAddress,
  (calibration, macAddress) => {
    if (!calibration[macAddress]) {
      return calibration;
    }
    return calibration && calibration[macAddress];
  },
);

export const getCalibrationPayload = createSelector(
  calibrationByMacSelector,
  (payload) => {
    if (payload) {
      return payload;
    }
  },
);
