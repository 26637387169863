import { createSlice } from '@reduxjs/toolkit';

// Set up initial state
const initialState = {
  value: '',
};

// Create slice logic
export const scannerSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {
    codeCaptured: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Actions are generated for each reducer function
export const { codeCaptured } = scannerSlice.actions;

// Export reducers to add to store
export default scannerSlice.reducer;
