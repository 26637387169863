import { renderStatusIcon } from '../utils/helpers';
import { Card, Heading } from '@enterprise-ui/canvas-ui-react';

import './styles/cardStyles.scss';

export const StatusCard = ({ status }) => {
  return (
    <Card elevation={0} className="customTextPosition" data-testid="statusCard">
      <Heading size={5} className="customHeader">
        Installation Status{' '}
      </Heading>
      <p className="hc-pt-sm hc-mb-dense">
        {renderStatusIcon(status)}
        {status}
      </p>
    </Card>
  );
};
