import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link as ReactLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { TrackPageView } from '../../utils/analyticsTracking';

import { Columns } from './columns';
import {
  getItems,
  getItemsExt,
  selectDeviceDetails,
} from '../../app/location/deviceItemSlice';

import {
  Anchor,
  Card,
  Grid,
  Layout,
  Heading,
  Breadcrumbs,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react';
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons';
import { DataTable } from 'ui-library';
import { useAuth } from '@praxis/component-auth';
import { useInstallerAuthContext } from '../../hooks/useInstallerAuthContext';

import './styles/styles.scss';

export const ActiveItems = () => {
  const auth = useAuth();
  const { session } = auth;
  const { user } = useInstallerAuthContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const activeDevicesDetails = useSelector(selectDeviceDetails);
  const { loading, error } = useSelector((state) => state.deviceByItem);

  const [activeDevices, setActiveDevices] = useState([]);
  const [localItemsLen, setLocalItemsLen] = useState();

  TrackPageView('/activeItems', 'activeItems');
  /**
   * To test in local storage properly, the item needs an array first. Run the following:
   *
   *  const testReaders = ['TTRN1x2y3w', 'Test']; //make sure these readers appear in store (use the lookup page to check) before testing
   *  localStorage.setItem('rfidReaders', JSON.stringify(testReaders));
   */

  //
  //

  useEffect(() => {
    const localItems = JSON.parse(localStorage.getItem('rfidReaders'));
    if (localItems) {
      setLocalItemsLen(localItems.length);
      if (session) {
        for (let i = 0; i < localItems.length; i++) {
          dispatch(getItems(localItems[i]));
        }
      } else if (user) {
        let { token } = user;
        for (let i = 0; i < localItems.length; i++) {
          let items = localItems[i];
          dispatch(getItemsExt({ items, token }));
        }
      }
    }
  }, [dispatch, user, session]);

  useEffect(() => {
    if (activeDevicesDetails && activeDevicesDetails.length > 0) {
      setActiveDevices(activeDevicesDetails);
    }
  }, [activeDevicesDetails]);

  const handleClick = (event) => {
    navigate(`/deviceDetails/${event.value}`, {
      state: { previousPath: pathname },
    });
  };

  const getRowNodeId = (data) => {
    return data.id;
  };

  const onGridReady = (e) => {
    if (activeDevicesDetails && activeDevicesDetails.length === 0) {
      setActiveDevices(activeDevicesDetails);
    }
  };

  return (
    <>
      <Layout.Body data-testid="ActiveItems" includeRail>
        <Card className="hc-pa-normal">
          <Grid.Container>
            <Breadcrumbs className="breadcrumbSpacing">
              <Breadcrumbs.Item as={ReactLink} to="/">
                Home
              </Breadcrumbs.Item>
              <Breadcrumbs.Item>Active Items</Breadcrumbs.Item>
            </Breadcrumbs>
            <Grid.Item xs={12}>
              <Heading size={3}>Active Items</Heading>
            </Grid.Item>
            {loading && <Spinner className="center" />}
            {error && (
              <Card elevation={0} className="hc-pa-normal cardContainer">
                <Input.Info error>
                  There was an issue loading active items. Please try again.
                </Input.Info>
              </Card>
            )}
            {activeDevices.length <= 0 && !error && (
              <Card
                elevation={0}
                className="hc-pa-normal cardContainer"
                style={{ margin: 'auto' }}
                data-testid="no_items"
              >
                <p className="hc-fs-xs iconPosition">
                  <EnterpriseIcon icon={InfoIcon} size="md" />
                </p>
                <p className="textPosition">
                  You don't have any active items right now.{' '}
                  <Anchor to="/install" as={ReactLink} className="iot-link">
                    <br />
                    Ready to onboard a new reader?
                  </Anchor>
                </p>
              </Card>
            )}
            {activeDevices.length === localItemsLen && ( // check for table to load after looping actions
              <Grid.Item xs={12}>
                <p className="hc-fs-xs inputPosition">
                  {activeDevices.length} results found{' '}
                </p>
                <div style={{ minWidth: '310px' }}>
                  <DataTable
                    getRowId={getRowNodeId}
                    rows={activeDevices}
                    cols={Columns}
                    onCellClicked={handleClick}
                    onGridReady={onGridReady}
                  />
                </div>
              </Grid.Item>
            )}
          </Grid.Container>
        </Card>
      </Layout.Body>
    </>
  );
};
